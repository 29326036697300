'use client';

import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import { usePathname } from 'next/navigation';

// Import image metadata
let imageMetadata: Record<string, any> = {};
try {
  imageMetadata = require('@/constants/imageMetadata.json');
} catch (e) {
  // This will be empty if the image optimization script hasn't been run
  console.warn('Image metadata not found. Run the image optimization script first.');
}

interface ResponsiveImageProps {
  src: string;
  alt: string;
  className?: string;
  priority?: boolean;
  sizes?: string;
  quality?: number;
  onLoad?: () => void;
  fetchPriority?: "high" | "low" | "auto";
}

/**
 * A responsive image component that automatically selects the appropriate image size
 * based on the device screen size, using our pre-optimized image variants.
 */
const ResponsiveImage: React.FC<ResponsiveImageProps> = ({
  src,
  alt,
  className = '',
  priority = false,
  sizes = '(max-width: 640px) 100vw, (max-width: 1024px) 50vw, 33vw',
  quality = 80,
  onLoad,
  fetchPriority,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const pathname = usePathname();
  
  // Detect if we're on a mobile route
  const isMobileRoute = pathname?.includes('/mobile');
  
  // Extract the base filename from the src
  const getBaseImageName = (path: string) => {
    // Remove path and extension
    const filename = path.split('/').pop()?.split('.')[0] || '';
    // Remove any suffixes like -mobile, -tablet, etc.
    return filename.split('-')[0];
  };
  
  const baseImageName = getBaseImageName(src);
  
  // Check if this is a blog image
  const isBlogImage = src.includes('/blog/');
  
  // Check if this image is from optimized directory
  const isOptimizedImage = src.includes('/optimized/');
  
  // Special case for the vocal production article image
  const isVocalProductionImage = src.includes('vocal-recording-session-studio-microphone-techniques-professional-2025');
  
  // Get the optimized image variants if available
  const imageVariants = imageMetadata[baseImageName] || null;
  
  // If we don't have optimized variants, fall back to the original src
  // For blog images, check if optimized versions exist
  let finalSrc = src;
  
  if (isBlogImage && !isOptimizedImage) {
    // Try to use optimized version for blog images
    const optimizedPath = src.replace('/images/blog/', '/images/blog/optimized/');
    finalSrc = optimizedPath;
  } else if (imageVariants) {
    // Use image variants if available
    finalSrc = isMobileRoute
      ? (imageVariants.mobile?.path || imageVariants.tablet?.path || src)
      : (imageVariants.desktop?.path || src);
  }
  
  // Get width and height for the selected variant
  const getImageDimensions = () => {
    if (!imageVariants) {
      // For blog images, use standard dimensions to prevent layout shifts
      if (isBlogImage) return { width: 800, height: 450 };
      
      // Default dimensions if no variants
      return { width: 1200, height: 800 };
    }
    
    const variant = isMobileRoute 
      ? (imageVariants.mobile || imageVariants.tablet || imageVariants.desktop)
      : (imageVariants.desktop || imageVariants.tablet || imageVariants.mobile);
    
    return {
      width: variant?.width || 1200,
      height: variant?.height || 800
    };
  };
  
  const { width, height } = getImageDimensions();
  
  // Get placeholder image if available
  const placeholderSrc = imageVariants?.placeholder?.path;
  
  // Handle image load
  const handleLoad = () => {
    setIsLoaded(true);
    if (onLoad) onLoad();
  };

  // Set appropriate loading strategy and quality
  // Reduced quality for blog images that aren't priority
  const imageQuality = isBlogImage 
    ? (priority ? 85 : 70) 
    : quality;
  
  // Set appropriate loading strategy based on priority
  // Never use lazy loading for priority images (LCP candidates)
  const loadingStrategy = priority ? 'eager' : 'lazy';
  
  // Set proper fetch priority
  const finalFetchPriority = priority ? 'high' : (fetchPriority || 'auto');
  
  // Preload critical blog images client-side
  useEffect(() => {
    if (priority && isBlogImage) {
      const link = document.createElement('link');
      link.rel = 'preload';
      link.as = 'image';
      link.href = finalSrc;
      link.imageSrcset = `${finalSrc} 1x`;
      document.head.appendChild(link);
      
      return () => {
        document.head.removeChild(link);
      };
    }
  }, [priority, isBlogImage, finalSrc]);
  
  return (
    <div className="relative" style={{ backgroundColor: '#0A0A0A' }}>
      <Image
        src={finalSrc}
        alt={alt}
        width={width}
        height={height}
        quality={imageQuality}
        priority={priority}
        sizes={sizes}
        onLoad={handleLoad}
        loading={loadingStrategy}
        decoding={priority ? 'sync' : 'async'}
        className={`transition-opacity duration-300 ${isLoaded ? 'opacity-100' : 'opacity-0'} ${priority ? 'lcp-image' : ''} w-full h-full object-cover`}
        style={{ 
          objectPosition: isVocalProductionImage ? 'center 35%' : 'center'
        }}
        placeholder={placeholderSrc ? 'blur' : 'empty'}
        blurDataURL={placeholderSrc || undefined}
        fetchPriority={finalFetchPriority}
      />
      
      {/* Development indicators removed */}
    </div>
  );
};

export default ResponsiveImage; 