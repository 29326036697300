/**
 * Utility functions for generating structured data (schema.org)
 */

interface ServiceOffer {
  name: string;
  description: string;
  price: number;
  priceCurrency: string;
}

interface ServiceSchemaProps {
  name: string;
  description: string;
  provider: string;
  serviceType: string;
  offers: ServiceOffer[];
}

interface WebpageSchemaProps {
  title: string;
  description: string;
  url: string;
  datePublished?: string;
  dateModified?: string;
}

/**
 * Generates schema.org Service structured data
 */
export function generateServiceSchema(props: ServiceSchemaProps) {
  return {
    "@context": "https://schema.org",
    "@type": "Service",
    "name": props.name,
    "description": props.description,
    "provider": {
      "@type": "Person",
      "name": props.provider
    },
    "serviceType": props.serviceType,
    "offers": props.offers.map(offer => ({
      "@type": "Offer",
      "name": offer.name,
      "description": offer.description,
      "price": offer.price,
      "priceCurrency": offer.priceCurrency
    }))
  };
}

/**
 * Generates schema.org WebPage structured data
 */
export function generateWebpageSchema(props: WebpageSchemaProps) {
  return {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": props.title,
    "description": props.description,
    "url": props.url,
    ...(props.datePublished && { "datePublished": props.datePublished }),
    ...(props.dateModified && { "dateModified": props.dateModified })
  };
}

/**
 * Generates schema.org Article structured data for blog posts
 */
export function generateArticleSchema(props: {
  title: string;
  description: string;
  url: string;
  imageUrl: string;
  datePublished: string;
  dateModified: string;
  authorName: string;
}) {
  return {
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": props.title,
    "description": props.description,
    "image": props.imageUrl,
    "datePublished": props.datePublished,
    "dateModified": props.dateModified,
    "author": {
      "@type": "Person",
      "name": props.authorName
    },
    "publisher": {
      "@type": "Person",
      "name": props.authorName,
      "logo": {
        "@type": "ImageObject",
        "url": "https://www.giovannicordova.com/images/logo.png"
      }
    },
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": props.url
    }
  };
}