'use client';

import { motion } from 'framer-motion';
import { useState } from 'react';
import { Section, navigationItems } from '@/constants/navigation';
import { MobileMenu } from './header/MobileMenu';
import { DesktopNav } from './header/DesktopNav';
import { Menu } from 'lucide-react';
import { BlurBackdrop } from './BlurBackdrop';

interface HeaderProps {
  activeSection: Section;
}

export default function Header({ activeSection }: HeaderProps) {
  const isHomePage = activeSection === 'home';
  const isBlogSection = activeSection === 'blog';
  const [isHoveringDropdown, setIsHoveringDropdown] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <>
      <BlurBackdrop isVisible={isHoveringDropdown} />
      <motion.header 
        className="w-full z-[100]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2, ease: "easeOut" }}
      >
        <nav className="relative">
          <div className="relative w-[90%] max-w-[var(--container-width)] mx-auto px-[var(--space-sm)] md:px-[var(--space-lg)] z-[100]">
            <div className="flex items-center justify-between h-auto py-6">
              {/* Desktop navigation */}
              <DesktopNav 
                items={navigationItems}
                activeSection={activeSection}
                isHomePage={isHomePage}
                isBlogSection={isBlogSection}
                onHoverChange={setIsHoveringDropdown}
              />

              {/* Mobile Menu Button */}
              <button
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className="md:hidden text-white/50 hover:text-white transition-colors duration-300"
              >
                <Menu size={24} />
              </button>

              {/* Mobile menu */}
              <MobileMenu 
                isOpen={isMobileMenuOpen}
                onClose={() => setIsMobileMenuOpen(false)}
                items={navigationItems}
                isHomePage={isHomePage}
              />
            </div>
          </div>
        </nav>
      </motion.header>
    </>
  );
} 