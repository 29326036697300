import { Review } from '@/data/reviews';

export const distributeReviews = (reviews: Review[], columnCount: number = 5): Review[][] => {
  const cols: Review[][] = Array.from({ length: columnCount }, () => []);
  reviews.forEach((review, index) => {
    cols[index % columnCount].push(review);
  });
  return cols;
};

export const shuffleReviews = (reviews: Review[], seed: number = new Date().getDate()): Review[] => {
  // Use a seeded random number generator for consistency
  const pseudoRandom = (n: number) => {
    let x = Math.sin(n + seed) * 10000;
    return x - Math.floor(x);
  };
  
  const shuffled = [...reviews];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(pseudoRandom(i) * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
}; 