export interface FaqItem {
  question: string;
  answer: string;
}

export interface FaqCategory {
  category: string;
  items: FaqItem[];
}

export const faqData: FaqCategory[] = [
  {
    category: "General Questions",
    items: [
      {
        question: "What services do you offer?",
        answer: "I offer professional mixing and mastering services for music, as well as audio production for podcasts and audiobooks. My expertise spans across various genres and formats, ensuring high-quality sound for your projects."
      },
      {
        question: "Where are you based?",
        answer: "I'm based in Italy but work with clients globally. Thanks to digital technology, I can collaborate with artists, producers, and content creators from anywhere in the world."
      },
      {
        question: "How long have you been working in audio production?",
        answer: "I've been working in professional audio production for over a decade, collaborating with major labels, independent artists, and content creators across various genres and formats."
      }
    ]
  },
  {
    category: "Mixing & Mastering",
    items: [
      {
        question: "What is the difference between mixing and mastering?",
        answer: "Mixing is the process of balancing individual tracks within a song, applying effects, and creating spatial relationships between elements. Mastering is the final step that optimizes the overall sound for distribution, ensuring consistency across all playback systems and platforms."
      },
      {
        question: "Do you use digital or analog processing?",
        answer: "I use a hybrid approach depending on the project needs. While I work primarily in the digital domain for flexibility and precision, I also have access to state-of-the-art analog equipment including tape machines, compressors, and EQs. This allows me to bring the warmth and character of analog when it benefits the project, while maintaining the efficiency and recall capabilities of digital workflows."
      },
      {
        question: "What genres do you work with?",
        answer: "I work with a wide range of genres including pop, rock, electronic, hip-hop, R&B, jazz, classical, and more. My approach adapts to the specific needs of each genre while maintaining the highest sound quality standards."
      },
      {
        question: "What do I need to provide for a mixing project?",
        answer: "For mixing, please provide individual track stems in high-quality format (WAV or AIFF, 44.1kHz/48kHz, 24-bit), a rough mix for reference, and any notes about your vision for the song. Properly labeled and organized files help streamline the process."
      },
      {
        question: "How many revisions do you offer?",
        answer: "I offer up to 5 revisions with my standard mixing and mastering packages. This allows us to fine-tune the sound until you're completely satisfied. Additional revisions can be arranged if needed."
      }
    ]
  },
  {
    category: "Podcast & Audiobook Production",
    items: [
      {
        question: "Can you help with my podcast production?",
        answer: "Yes, I offer comprehensive podcast production services including editing, mixing, noise reduction, and mastering to ensure your podcast sounds professional and engaging to listeners across all platforms."
      },
      {
        question: "What makes audiobook production different from other audio work?",
        answer: "Audiobook production requires specialized techniques focused on voice clarity, consistent levels, proper pacing, and careful attention to technical specifications required by platforms like Audible. I ensure your audiobook meets all industry standards while maintaining a natural, engaging sound."
      },
      {
        question: "Do you offer voice enhancement for spoken word content?",
        answer: "Yes, I offer voice enhancement services that improve clarity, warmth, and presence while removing distracting elements like mouth clicks, background noise, and inconsistent levels. This helps create a professional, immersive listening experience."
      }
    ]
  },
  {
    category: "Process & Workflow",
    items: [
      {
        question: "What is your typical turnaround time?",
        answer: "Turnaround time varies by project complexity. Typically, mixing takes 3-5 business days per song, while mastering takes 1-2 days. Podcast episodes usually require 1-3 days, and audiobooks are quoted based on length. Rush services are available upon request."
      },
      {
        question: "How do we communicate during the project?",
        answer: "I maintain clear communication throughout the project via email or your preferred platform. I'm available for calls to discuss details and provide regular updates on progress. My goal is to ensure your vision is fully understood and realized."
      },
      {
        question: "How do I send my files?",
        answer: "You can send files through file transfer services like Dropbox, Google Drive, or WeTransfer. For large projects, I can provide access to my secure file transfer system. I'll provide clear instructions based on your project's specific needs."
      }
    ]
  },
  {
    category: "Pricing & Payments",
    items: [
      {
        question: "How is pricing determined?",
        answer: "Pricing is determined by project complexity, scope, and timeframe. I offer customized quotes based on your specific needs rather than a one-size-fits-all approach. Contact me with your project details for a personalized quote."
      },
      {
        question: "What payment methods do you accept?",
        answer: "Payment methods will be discussed privately. For all projects, I typically require a 50% deposit to secure your booking, with the balance due upon completion before delivery of final files."
      },
      {
        question: "Do you offer packages for multiple songs or episodes?",
        answer: "Yes, I offer discounted rates for album projects, podcast series, and other multi-track productions. These packages are customized based on the number of tracks and specific requirements of your project."
      }
    ]
  }
]; 