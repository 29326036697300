import React from 'react';
import { Review } from '@/data/reviews';

interface EnhancedReviewProps {
  review: Review;
  children?: React.ReactNode;
  className?: string;
}

/**
 * Enhances reviews with semantic SEO structure without changing visuals
 */
export const EnhancedReview: React.FC<EnhancedReviewProps> = ({ 
  review, 
  children,
  className = "" 
}) => {
  // Extract keywords from review text
  const extractKeywords = (text: string): string[] => {
    const keywordMap: Record<string, string> = {
      "mastering": "professional mastering",
      "mixing": "professional mixing",
      "communication": "communication",
      "professional": "professional audio",
      "quality": "sound quality",
      "recommend": "recommended engineer",
      "second time": "repeat client",
      "artistry": "artistic approach",
      "voice": "voice clarity",
      "podcast": "podcast production",
      "audiobook": "audiobook production"
    };
    
    return Object.entries(keywordMap)
      .filter(([key]) => text.toLowerCase().includes(key.toLowerCase()))
      .map(([_, value]) => value);
  };

  const reviewKeywords = extractKeywords(review.text);
  
  return (
    <article 
      itemScope 
      itemType="https://schema.org/Review"
      className={className}
    >
      {/* Keep original component structure but add semantic markup */}
      <div className="review-content">
        {children}
      </div>
      
      {/* Add hidden SEO metadata */}
      <div itemProp="author" itemScope itemType="https://schema.org/Person" className="hidden">
        <meta itemProp="name" content={review.author} />
      </div>
      <meta itemProp="datePublished" content={review.date || new Date().toISOString().split('T')[0]} />
      <div itemProp="reviewRating" itemScope itemType="https://schema.org/Rating" className="hidden">
        <meta itemProp="ratingValue" content={review.rating.toString()} />
        <meta itemProp="bestRating" content="5" />
      </div>
      <meta itemProp="reviewBody" content={review.text} />
      {reviewKeywords.length > 0 && (
        <meta name="keywords" content={reviewKeywords.join(', ')} />
      )}
      <div itemProp="itemReviewed" itemScope itemType="https://schema.org/MusicGroup" className="hidden">
        <meta itemProp="name" content="Giovanni Cordova - Audio Engineer" />
        <meta itemProp="description" content="Professional audio mixing and mastering services" />
        <meta itemProp="url" content="https://www.giovannicordova.com/" />
      </div>
    </article>
  );
};

export default EnhancedReview; 