import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { Menu, Share2 } from 'lucide-react';
import { usePathname } from 'next/navigation';

interface HeaderProps {
  isHeaderVisible: boolean;
  isGalleryPage: boolean;
  isHomePage: boolean;
  setIsMobileMenuOpen: (open: boolean) => void;
  setShowShareMenu: (show: boolean) => void;
  handleNavigation: (href: string) => void;
}

export default function Header({
  isHeaderVisible,
  isGalleryPage,
  isHomePage,
  setIsMobileMenuOpen,
  setShowShareMenu,
  handleNavigation
}: HeaderProps) {
  const pathname = usePathname();
  
  // Handle the share button click
  const handleShareClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent event bubbling
    setShowShareMenu(true);
  };

  // Handle the menu button click
  const handleMenuClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent event bubbling
    setIsMobileMenuOpen(true);
  };

  const isBlogPage = pathname?.includes('/blog');

  return (
    <header className="w-full z-[100] bg-[#0A0A0A]">
      <div className="flex items-center justify-between px-4 py-3">
        {/* Logo or placeholder - same width for consistency */}
        <div className="flex items-center w-10">
          {isHomePage && (
            <Link href="/" className="flex items-center">
              <div className="relative h-10 w-10">
                <Image
                  src="/images/LOGO GC_1.png"
                  alt="GC Logo"
                  width={40}
                  height={40}
                  sizes="40px"
                  className="object-contain brightness-0 invert contrast-200"
                  priority
                />
              </div>
            </Link>
          )}
        </div>
        
        {/* Right side buttons with consistent positioning */}
        <div className="flex items-center justify-end w-24">
          {/* Share Button - On homepage and blog pages */}
          {(isHomePage || isBlogPage) && (
            <button
              onClick={handleShareClick}
              aria-label="Share"
              type="button"
              className="relative p-3 -m-1 text-white/80 hover:text-white active:text-white focus:outline-none touch-manipulation"
            >
              <Share2 size={22} strokeWidth={1.5} />
            </button>
          )}
          
          {/* Menu Button - Fixed position */}
          <div className="ml-5">
            <button
              onClick={handleMenuClick}
              aria-label="Menu"
              type="button"
              className="relative p-3 -m-1 text-white/80 hover:text-white active:text-white focus:outline-none touch-manipulation"
            >
              <Menu size={24} strokeWidth={1.5} />
            </button>
          </div>
        </div>
      </div>
    </header>
  );
} 