'use client';

import Header from '@/components/Header';
import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';
import { WebpageStructuredData } from '@/components/StructuredData';

export default function GalleryPage() {
  const [isHoveringASP, setIsHoveringASP] = useState(false);
  const [isHoveringCR, setIsHoveringCR] = useState(false);

  const aspImages = [
    '/images/gallery/giovanni-cordova-studio-mixing-mastering-grammy-listening-recording-audio-professional-2025-13.jpg',
    '/images/gallery/giovanni-cordova-studio-mixing-mastering-outboard-analog-1176-ln-1178-compressor-2025-11.jpg',
    '/images/gallery/giovanni-cordova-studio-vocal-recording-james-herrera-ssl-g-desk-2025-02.jpg',
    '/images/gallery/giovanni-cordova-studio-mixing-mastering-professional-audio-engineer-producer-2025-15.jpg',
    '/images/gallery/giovanni-cordova-studio-mixing-ssl-lexicon-vintage-desk-knobs-2025-04.jpg',
    '/images/gallery/giovanni-cordova-studio-mixing-mastering-specialist-studio-analog-gear-analog-tape-2025-14.jpg',
  ];

  const crImages = [
    '/images/gallery/giovanni-cordova-studio-best-mixing-mastering-neve-desk-london-2025-06.jpg',
    '/images/gallery/giovanni-cordova-studio-best-mixing-mastering-analog-tape-london-2025-07.jpg',
    '/images/gallery/giovanni-cordova-studio-professional-mixing-mastering-vintage-equipment-london-2025-08.jpg',
  ];

  return (
    <>
      {/* SEO Structured Data */}
      <WebpageStructuredData 
        title="Studio Gallery | Giovanni Cordova"
        description="Explore Giovanni Cordova's professional audio studios where mixing, mastering, and audio production take place. High-quality equipment and environments for superior sound."
        url="https://www.giovannicordova.com/gallery"
      />
      
      <Header activeSection="gallery" />
      <main className="min-h-screen bg-[#0a0a0a] py-24 space-y-24">
        <div className="w-[95%] max-w-[1800px] mx-auto relative group">
          <div className="absolute -top-12 right-6 px-5 h-8 rounded-md bg-[#0A0A0A]/90 text-[12px] font-light flex items-center opacity-0 group-hover:opacity-100 transition-opacity duration-200">
            <div className="w-4 h-4 rounded-full overflow-hidden relative mr-2">
              <div className="absolute inset-0 bg-[#009246] w-1/3" />
              <div className="absolute inset-0 left-1/3 bg-white w-1/3" />
              <div className="absolute inset-0 left-2/3 bg-[#CE2B37] w-1/3" />
            </div>
            <span className="text-white/50 font-light">
              <Link href="http://www.ritmoeblu.com" target="_blank" rel="noopener noreferrer" className="text-white hover:opacity-80">
                Ritmo & Blu Studio
              </Link>
              , Mid <span className="text-white">2024</span>. Pictures by{' '}
              <Link href="https://www.andreas-senoner.com/en/" target="_blank" rel="noopener noreferrer" className="text-white hover:opacity-80">
                Andreas Senoner
              </Link>
              {' '}and{' '}
              <Link href="https://mintmediahouse.com" target="_blank" rel="noopener noreferrer" className="text-white hover:opacity-80">
                mintmediahouse
              </Link>
            </span>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
            {aspImages.map((src, index) => (
              <div 
                key={index} 
                className="relative w-full aspect-[4/3] bg-neutral-900 rounded-xl overflow-hidden"
              >
                <Image
                  src={src}
                  alt={index === 0 
                    ? "Professional audio monitoring room at Ritmo & Blu Studio where Grammy-caliber mixes and masters are produced"
                    : index === 1 
                    ? "Vintage 1176 compressors used for premium audio mixing and mastering at professional Italian recording studio"
                    : index === 2
                    ? "SSL G desk setup for vocal recording sessions - professional studio environment for capturing pristine vocal performances"
                    : index === 3
                    ? "High-end mixing station in premium Italian recording studio where top-quality audio productions are engineered"
                    : index === 4
                    ? "Vintage SSL console with Lexicon processor - professional analog equipment for creating warm, dynamic mixes"
                    : "Analog tape machine for premium audio mastering - vintage equipment used to deliver rich, authentic sound quality"
                  }
                  fill
                  className="object-cover"
                  sizes="(max-width: 768px) 95vw, (max-width: 1200px) 47vw, 31vw"
                  priority={index < 3}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="w-[95%] max-w-[1800px] mx-auto relative group">
          <div className="absolute -top-12 right-6 px-5 h-8 rounded-md bg-[#0A0A0A]/90 text-[12px] font-light flex items-center opacity-0 group-hover:opacity-100 transition-opacity duration-200">
            <div className="w-4 h-4 rounded-full overflow-hidden relative mr-2" style={{ backgroundImage: "url('/images/flags/UK.jpg')", backgroundSize: "cover" }}></div>
            <span className="text-white/50 font-light">
              <span className="text-white">London</span>, Early <span className="text-white">2024</span>. Pictures by{' '}
              <Link href="https://www.instagram.com/mario_simanovsky/" target="_blank" rel="noopener noreferrer" className="text-white hover:opacity-80">
                Mario Simanovsky
              </Link>
            </span>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
            {crImages.map((src, index) => (
              <div 
                key={index} 
                className="relative w-full aspect-[4/3] bg-neutral-900 rounded-xl overflow-hidden"
              >
                <Image
                  src={src}
                  alt={index === 0 
                    ? "Professional Neve mixing console in London studio where premium audio productions are created"
                    : index === 1
                    ? "Analog tape machine for high-fidelity mastering - London studio equipment delivering warm, authentic sound character"
                    : "Vintage recording equipment in professional London studio - premium tools for world-class mixing and mastering services"
                  }
                  fill
                  className="object-cover"
                  sizes="(max-width: 768px) 95vw, (max-width: 1200px) 47vw, 31vw"
                  priority={index < 3}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="w-full text-center text-xl">
          <span className="text-white/80 font-light">Let's connect</span>
          <span className="mx-2 text-3xl text-white">→</span>
          <Link 
            href="https://www.instagram.com/giovicordova/" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="relative inline-block text-white font-bold after:content-[''] after:absolute after:w-full after:h-[1px] after:bg-white after:left-0 after:bottom-0 after:origin-left after:scale-x-0 hover:after:scale-x-100 after:transition-transform after:duration-300"
          >
            Instagram
          </Link>
        </div>
      </main>
    </>
  );
} 