import React from 'react';
import Script from 'next/script';
import { generateWebpageSchema } from '@/utils/structuredData';

interface ServiceStructuredDataProps {
  name: string;
  description: string;
  url: string;
  serviceType: string;
  provider?: {
    name: string;
    description: string;
    image: string;
  };
  price?: {
    amount: string;
    currency: string;
    description?: string;
  };
  areaServed?: string;
}

interface ServiceJsonLd {
  '@context': string;
  '@type': string;
  name: string;
  url: string;
  provider: {
    '@type': string;
    name: string;
    description: string;
    image: string;
    address: {
      '@type': string;
      addressCountry: string;
    };
  };
  serviceType: string;
  description: string;
  areaServed: {
    '@type': string;
    name: string;
  };
  offers?: {
    '@type': string;
    price: string;
    priceCurrency: string;
    description: string;
  };
}

export const ServiceStructuredData: React.FC<ServiceStructuredDataProps> = ({
  name,
  description,
  url,
  serviceType,
  provider = {
    name: 'Giovanni Cordova',
    description: 'Professional Mixing & Mastering Engineer',
    image: 'https://www.giovannicordova.com/images/giovanni-cordova-studio-mixing-mastering-neve-console-london-uk-2025.jpg',
  },
  price,
  areaServed = 'Worldwide',
}) => {
  const jsonLd: ServiceJsonLd = {
    '@context': 'https://schema.org',
    '@type': 'Service',
    'name': name,
    'url': url,
    'provider': {
      '@type': 'ProfessionalService',
      'name': provider.name,
      'description': provider.description,
      'image': provider.image,
      'address': {
        '@type': 'PostalAddress',
        'addressCountry': 'Italy'
      }
    },
    'serviceType': serviceType,
    'description': description,
    'areaServed': {
      '@type': 'Country',
      'name': areaServed
    }
  };

  // Add price information if available
  if (price) {
    jsonLd.offers = {
      '@type': 'Offer',
      'price': price.amount,
      'priceCurrency': price.currency,
      'description': price.description || `Professional ${serviceType.toLowerCase()} services`
    };
  }

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
    />
  );
};

interface WebpageStructuredDataProps {
  title: string;
  description: string;
  url?: string;
  datePublished?: string;
  dateModified?: string;
}

/**
 * WebpageStructuredData component for adding WebPage schema.org data
 */
export function WebpageStructuredData({
  title,
  description,
  url = 'https://www.giovannicordova.com',
  datePublished,
  dateModified
}: WebpageStructuredDataProps) {
  const schema = generateWebpageSchema({
    title,
    description,
    url,
    datePublished,
    dateModified
  });

  return (
    <Script
      id="structured-data-webpage"
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
    />
  );
}

interface PersonStructuredDataProps {
  name: string;
  description: string;
  url: string;
  image: string;
  jobTitle: string;
  sameAs?: string[];
}

export const PersonStructuredData: React.FC<PersonStructuredDataProps> = ({
  name,
  description,
  url,
  image,
  jobTitle,
  sameAs = [],
}) => {
  const jsonLd = {
    '@context': 'https://schema.org',
    '@type': 'Person',
    'name': name,
    'description': description,
    'url': url,
    'image': image,
    'jobTitle': jobTitle,
    'sameAs': sameAs,
    'worksFor': {
      '@type': 'Organization',
      'name': name,
      'url': url
    }
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
    />
  );
};

// Add new Organization structured data component for better search results
interface OrganizationStructuredDataProps {
  name: string;
  description: string;
  url: string;
  logo: string;
  image: string;
  telephone?: string;
  email?: string;
  foundingDate?: string;
  sameAs?: string[];
}

export const OrganizationStructuredData: React.FC<OrganizationStructuredDataProps> = ({
  name,
  description,
  url,
  logo,
  image,
  telephone,
  email,
  foundingDate = '2010',
  sameAs = [],
}) => {
  const jsonLd: Record<string, any> = {
    '@context': 'https://schema.org',
    '@type': 'ProfessionalService',
    'name': name,
    'description': description,
    'url': url,
    'logo': logo,
    'image': image,
    'foundingDate': foundingDate,
    'sameAs': sameAs,
    'address': {
      '@type': 'PostalAddress',
      'addressCountry': 'Italy'
    },
  };
  
  if (telephone) {
    jsonLd['telephone'] = telephone;
  }
  
  if (email) {
    jsonLd['email'] = email;
  }

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
    />
  );
};

// Add BreadcrumbList component for better search results
interface BreadcrumbItem {
  name: string;
  url: string;
}

interface BreadcrumbStructuredDataProps {
  items: BreadcrumbItem[];
}

export const BreadcrumbStructuredData: React.FC<BreadcrumbStructuredDataProps> = ({
  items
}) => {
  const jsonLd = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    'itemListElement': items.map((item, index) => ({
      '@type': 'ListItem',
      'position': index + 1,
      'name': item.name,
      'item': item.url
    }))
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
    />
  );
};

// Add FAQ schema for FAQ pages
interface FAQItem {
  question: string;
  answer: string;
}

interface FAQStructuredDataProps {
  items: FAQItem[];
}

export const FAQStructuredData: React.FC<FAQStructuredDataProps> = ({
  items
}) => {
  const jsonLd = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    'mainEntity': items.map(item => ({
      '@type': 'Question',
      'name': item.question,
      'acceptedAnswer': {
        '@type': 'Answer',
        'text': item.answer
      }
    }))
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
    />
  );
};

// Add Review schema component
interface ReviewStructuredDataProps {
  itemReviewed: {
    name: string;
    description: string;
    url: string;
    image?: string;
  };
  reviews: {
    author: string;
    datePublished: string;
    reviewBody: string;
    reviewRating: {
      ratingValue: number;
      bestRating?: number;
      worstRating?: number;
    };
  }[];
}

export const ReviewStructuredData: React.FC<ReviewStructuredDataProps> = ({
  itemReviewed,
  reviews
}) => {
  const jsonLd = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    'name': itemReviewed.name,
    'description': itemReviewed.description,
    'url': itemReviewed.url,
    'image': itemReviewed.image,
    'review': reviews.map(review => ({
      '@type': 'Review',
      'author': {
        '@type': 'Person',
        'name': review.author
      },
      'datePublished': review.datePublished,
      'reviewBody': review.reviewBody,
      'reviewRating': {
        '@type': 'Rating',
        'ratingValue': review.reviewRating.ratingValue,
        'bestRating': review.reviewRating.bestRating || 5,
        'worstRating': review.reviewRating.worstRating || 1
      }
    }))
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
    />
  );
};

// Add ArticleStructuredData component for blog posts
interface ArticleStructuredDataProps {
  headline: string;
  description: string;
  url: string;
  image: string;
  datePublished: string;
  dateModified?: string;
  authorName: string;
  publisherName: string;
  publisherLogo: string;
}

export const ArticleStructuredData: React.FC<ArticleStructuredDataProps> = ({
  headline,
  description,
  url,
  image,
  datePublished,
  dateModified,
  authorName,
  publisherName,
  publisherLogo
}) => {
  const jsonLd = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    'headline': headline,
    'description': description,
    'image': image,
    'datePublished': datePublished,
    'dateModified': dateModified || datePublished,
    'url': url,
    'author': {
      '@type': 'Person',
      'name': authorName
    },
    'publisher': {
      '@type': 'Organization',
      'name': publisherName,
      'logo': {
        '@type': 'ImageObject',
        'url': publisherLogo
      }
    },
    'mainEntityOfPage': {
      '@type': 'WebPage',
      '@id': url
    }
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
    />
  );
}; 