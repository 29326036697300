'use client';

import React from 'react';
import Image from 'next/image';
import { useState, useEffect } from 'react';

interface BasicImageProps {
  src: string;
  alt: string;
  className?: string;
  style?: React.CSSProperties;
  width?: number | string;
  height?: number | string;
  loading?: 'lazy' | 'eager';
  priority?: boolean;
  sizes?: string;
  quality?: number;
  fetchPriority?: 'high' | 'low' | 'auto';
  decoding?: 'sync' | 'async' | 'auto';
}

/**
 * A basic image component that uses Next.js Image for optimization
 * Provides better performance than native img tags
 */
const BasicImage = ({
  src,
  alt,
  className = '',
  style = {},
  width,
  height,
  loading,
  priority = false,
  sizes = '(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw',
  quality = 75,
  fetchPriority,
  decoding,
}: BasicImageProps) => {
  // Error state to handle image load failures without console errors
  const [imgError, setImgError] = useState(false);
  // Client-side state to track if we're in the browser
  const [isMounted, setIsMounted] = useState(false);
  // Add loading state to handle fade-in animation
  const [isLoaded, setIsLoaded] = useState(false);
  
  // Handle error gracefully 
  const handleError = () => {
    setImgError(true);
  };

  // Handle image load completion
  const handleLoad = () => {
    setIsLoaded(true);
  };

  // Set mounted state on client side
  useEffect(() => {
    setIsMounted(true);
    // Check if image is already loaded from cache
    if (typeof window !== 'undefined') {
      const img = new window.Image();
      img.src = src;
      if (img.complete) {
        setIsLoaded(true);
      }
    }
  }, [src]);

  // If error occurred, show a placeholder with alt text
  if (imgError) {
    return (
      <div 
        className={`bg-gray-900 flex items-center justify-center ${className}`}
        style={{
          ...style,
          minHeight: typeof height === 'number' ? `${height}px` : height || '200px',
        }}
      >
        <span className="text-white/60 text-sm text-center p-4">{alt}</span>
      </div>
    );
  }

  // Define standard image sizes
  const standardWidth = typeof width === 'number' ? width : 800;
  const standardHeight = typeof height === 'number' ? height : 600;

  return (
    <div 
      className={className} 
      style={{
        ...style,
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: '#0A0A0A',
      }}
    >
      <Image
        src={src}
        alt={alt}
        style={{
          objectFit: 'cover',
          width: '100%',
          height: '100%',
          opacity: isLoaded ? 1 : 0,
          transition: 'opacity 0.3s ease-in-out',
        }}
        width={standardWidth}
        height={standardHeight}
        quality={quality}
        loading={loading || (priority ? 'eager' : 'lazy')}
        priority={priority}
        sizes={sizes}
        onError={handleError}
        onLoad={handleLoad}
        placeholder="blur"
        blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkYPj/HwADBwIAMCbHYQAAAABJRU5ErkJggg=="
        fetchPriority={fetchPriority || (priority ? 'high' : 'auto')}
        decoding={decoding || (priority ? 'sync' : 'async')}
      />
    </div>
  );
};

export default BasicImage; 