import React from 'react';

interface SeoHeadingProps {
  children: React.ReactNode;
  level?: 1 | 2 | 3 | 4 | 5 | 6;
  className?: string;
  itemProp?: string;
  id?: string;
}

/**
 * Semantic heading component with SEO attributes
 */
export const SeoHeading: React.FC<SeoHeadingProps> = ({ 
  children, 
  level = 2, 
  className = "",
  itemProp,
  id
}) => {
  const renderHeading = () => {
    switch(level) {
      case 1:
        return <h1 className={className} itemProp={itemProp} id={id}>{children}</h1>;
      case 2:
        return <h2 className={className} itemProp={itemProp} id={id}>{children}</h2>;
      case 3:
        return <h3 className={className} itemProp={itemProp} id={id}>{children}</h3>;
      case 4:
        return <h4 className={className} itemProp={itemProp} id={id}>{children}</h4>;
      case 5:
        return <h5 className={className} itemProp={itemProp} id={id}>{children}</h5>;
      case 6:
        return <h6 className={className} itemProp={itemProp} id={id}>{children}</h6>;
      default:
        return <h2 className={className} itemProp={itemProp} id={id}>{children}</h2>;
    }
  };
  
  return renderHeading();
};

export default SeoHeading; 