import React from 'react';
import { ChevronRight, X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { navigationItems } from '@/constants/navigation';
import { usePathname } from 'next/navigation';

// Define shared animation settings for consistency
const TRANSITION = {
  duration: 0.2,
  ease: [0.16, 1, 0.3, 1]
};

// Font sizes (in pixels) for consistency with page titles
const FONT_SIZES = {
  menuItem: "32px", // Matches mobile page title size
  subMenuItem: "24px" // 25% smaller than main menu items
};

interface MobileMenuProps {
  isMobileMenuOpen: boolean;
  activeDropdown: string | null;
  setIsMobileMenuOpen: (open: boolean) => void;
  setActiveDropdown: (dropdown: string | null) => void;
  handleNavigation: (href: string, e?: React.MouseEvent) => void;
}

export default function MobileMenu({
  isMobileMenuOpen,
  activeDropdown,
  setIsMobileMenuOpen,
  setActiveDropdown,
  handleNavigation
}: MobileMenuProps) {
  const pathname = usePathname();
  const isHomePage = pathname === '/' || pathname === '/mobile';

  // Handle close button click
  const handleCloseClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent event bubbling
    setIsMobileMenuOpen(false);
  };

  // Handle dropdown toggle
  const handleDropdownToggle = (e: React.MouseEvent, label: string) => {
    e.stopPropagation(); // Prevent event bubbling
    setActiveDropdown(activeDropdown === label ? null : label);
  };

  // Handle navigation item click
  const handleNavigationClick = (e: React.MouseEvent, href: string) => {
    e.stopPropagation(); // Prevent event bubbling
    handleNavigation(href, e);
  };

  return (
    <AnimatePresence>
      {isMobileMenuOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={TRANSITION}
          className="fixed inset-0 z-[300] bg-[#0A0A0A]"
        >
          {/* Close Button - Exactly aligned with hamburger menu position */}
          <div className="absolute top-3 right-4 z-[310]">
            <button
              onClick={handleCloseClick}
              aria-label="Close menu"
              type="button"
              className="relative p-3 -m-1 rounded-full text-white hover:text-white active:text-white touch-manipulation"
            >
              <X size={24} strokeWidth={1.5} />
            </button>
          </div>
          
          {/* Menu Content */}
          <div className="h-full w-full pt-16 pb-20 px-4 overflow-y-auto">
            <nav className="flex flex-col space-y-4 max-w-full mx-auto">
              {/* Home menu item - Only shown when not on homepage */}
              {!isHomePage && (
                <div className="w-full">
                  <button
                    onClick={(e) => handleNavigationClick(e, "/")}
                    aria-label="Home"
                    type="button"
                    className="py-2 text-left font-medium touch-manipulation truncate text-steel-gradient"
                    style={{ fontSize: FONT_SIZES.menuItem }}
                  >
                    Home
                  </button>
                </div>
              )}
              
              {/* Regular navigation items */}
              {navigationItems.map((item) => (
                <div key={item.label} className="w-full">
                  {item.dropdown ? (
                    <div className="mb-1">
                      {/* Menu Item with Dropdown */}
                      <button
                        onClick={(e) => handleDropdownToggle(e, item.label)}
                        aria-label={`${item.label} menu`}
                        type="button"
                        className="flex items-center w-full py-2 text-left touch-manipulation"
                      >
                        <span className="font-medium truncate text-steel-gradient" style={{ fontSize: FONT_SIZES.menuItem }}>
                          {item.label}
                        </span>
                        {/* Only show arrow for non-Explorer items */}
                        {item.label !== "Explore" && (
                          <ChevronRight
                            size={22}
                            className={`transition-transform duration-300 ml-2 text-white/80 ${
                              activeDropdown === item.label ? 'rotate-90' : ''
                            }`}
                          />
                        )}
                      </button>
                      
                      {/* Submenu - No indentation */}
                      <AnimatePresence>
                        {activeDropdown === item.label && (
                          <motion.div
                            initial={{ height: 0, opacity: 0 }}
                            animate={{ height: 'auto', opacity: 1 }}
                            exit={{ height: 0, opacity: 0 }}
                            transition={TRANSITION}
                            className="overflow-hidden pt-1"
                          >
                            <div className="flex flex-col space-y-2">
                              {item.dropdown.map((subitem) => (
                                <button
                                  key={subitem.label}
                                  onClick={(e) => handleNavigationClick(e, subitem.href)}
                                  aria-label={subitem.label}
                                  type="button"
                                  className="py-2 text-left touch-manipulation truncate text-steel-gradient"
                                  style={{ fontSize: FONT_SIZES.subMenuItem }}
                                >
                                  {subitem.label}
                                </button>
                              ))}
                            </div>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                  ) : (
                    // Simple Menu Item
                    <button
                      onClick={(e) => handleNavigationClick(e, item.href)}
                      aria-label={item.label}
                      type="button"
                      className="py-2 text-left font-medium touch-manipulation truncate text-steel-gradient flex items-center"
                      style={{ fontSize: FONT_SIZES.menuItem }}
                    >
                      {item.label}
                      {/* Add orange dot indicator after Pricing and Contact text */}
                      {item.label === "Pricing and Contact" && (
                        <span className="inline-block ml-2 w-2 h-2 rounded-full bg-orange-500 self-center flex-shrink-0" />
                      )}
                    </button>
                  )}
                </div>
              ))}
            </nav>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
} 