'use client';

import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';

// Import translation files
import enTranslations from '@/translations/en.json';
import esTranslations from '@/translations/es.json';

// Define available languages
export type Language = 'en' | 'es';

// Define the translations type
export type Translations = typeof enTranslations;

// Define the context type
type LanguageContextType = {
  language: Language;
  translations: Translations;
  setLanguage: (lang: Language) => void;
  t: (key: string) => string;
};

// Create context with a default value
const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

// Create a provider component
export const LanguageProvider = ({ children }: { children: ReactNode }) => {
  // Initialize with English by default
  const [language, setLanguage] = useState<Language>('en');
  const [translations, setTranslations] = useState<Translations>(enTranslations);

  // Update translations when language changes
  useEffect(() => {
    setTranslations(language === 'en' ? enTranslations : esTranslations);
    
    // Optionally store the language preference in localStorage
    localStorage.setItem('preferredLanguage', language);
    
    // Update HTML lang attribute
    document.documentElement.lang = language;
  }, [language]);

  // Initialize with user's preferred language if available
  useEffect(() => {
    const savedLanguage = localStorage.getItem('preferredLanguage') as Language | null;
    
    if (savedLanguage && (savedLanguage === 'en' || savedLanguage === 'es')) {
      setLanguage(savedLanguage);
    } else {
      // If no saved preference, try to detect browser language
      const browserLang = navigator.language.split('-')[0];
      if (browserLang === 'es') {
        setLanguage('es');
      }
    }
  }, []);

  // Create a translation helper function
  const t = (key: string): string => {
    // Split the key into parts
    const parts = key.split('.');
    
    // Traverse the translations object
    let result: any = translations;
    
    for (const part of parts) {
      if (result && typeof result === 'object' && part in result) {
        result = result[part];
      } else {
        // Key not found, return the key itself as fallback
        return key;
      }
    }
    
    return typeof result === 'string' ? result : key;
  };

  return (
    <LanguageContext.Provider value={{ language, translations, setLanguage, t }}>
      {children}
    </LanguageContext.Provider>
  );
};

// Create a custom hook to use the language context
export const useLanguage = () => {
  const context = useContext(LanguageContext);
  
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  
  return context;
}; 