'use client';

import Link from 'next/link';
import { NavItem, Section } from '@/constants/navigation';

interface MobileMenuProps {
  isOpen: boolean;
  onClose: () => void;
  items: NavItem[];
  isHomePage: boolean;
}

export const MobileMenu = ({ isOpen, onClose, items, isHomePage }: MobileMenuProps) => {
  return (
    <div 
      className={`fixed inset-0 bg-[#0A0A0A] z-40 transition-transform duration-300 ease-in-out transform ${
        isOpen ? 'translate-x-0' : 'translate-x-full'
      } md:hidden`}
    >
      <div className="flex flex-col items-center justify-center h-full space-y-8 text-center">
        <Link 
          href="/"
          onClick={onClose}
          className={isHomePage ? 
            "text-[18px] tracking-normal font-medium text-white" :
            "text-[18px] tracking-normal font-normal text-white/60 hover:text-white"}
        >
          {isHomePage ? 'Giovanni Cordova' : 'Home'}
        </Link>
        
        {items.map((item) => (
          <div key={item.href} className="flex flex-col items-center space-y-4">
            {item.dropdown ? (
              <span className="text-[18px] tracking-normal font-normal text-white/60">
                {item.label}
              </span>
            ) : (
              <Link 
                href={item.href}
                className="text-[18px] tracking-normal font-normal text-white/60 hover:text-white"
                onClick={onClose}
              >
                {item.label}
              </Link>
            )}
            {item.dropdown?.map((dropdownItem) => (
              <Link 
                key={dropdownItem.href}
                href={dropdownItem.href}
                className="text-[16px] tracking-normal font-normal text-white/60 hover:text-white"
                onClick={onClose}
              >
                {dropdownItem.label}
              </Link>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}; 