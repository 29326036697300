'use client';

import React, { useState, useEffect } from 'react';

interface AnimatedPlaylistProps {
  src: string;
  className?: string;
  title?: string;
}

export const AnimatedPlaylist = ({ 
  src,
  className = '',
  title = 'Spotify Playlist'
}: AnimatedPlaylistProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(false);

  // Simulate loading state and then fade in
  useEffect(() => {
    // Start with a short delay to ensure the component is mounted
    const loadingTimer = setTimeout(() => {
      setIsLoading(false);
    }, 300);

    // Add a little more delay before showing to ensure smooth animation
    const visibilityTimer = setTimeout(() => {
      setIsVisible(true);
    }, 500);

    return () => {
      clearTimeout(loadingTimer);
      clearTimeout(visibilityTimer);
    };
  }, []);

  return (
    <div className={`relative w-full h-full ${className}`}>
      {/* Loading overlay */}
      {isLoading && (
        <div className="absolute inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center rounded-2xl">
          <div className="w-8 h-8 border-2 border-white/20 border-t-white/80 rounded-full animate-spin"></div>
        </div>
      )}
      
      {/* Spotify iframe with fade-in effect */}
      <div 
        className={`w-full h-full overflow-hidden transition-opacity duration-1000 ease-in-out rounded-2xl ${isVisible ? 'opacity-100' : 'opacity-0'}`}
      >
        <iframe
          src={src}
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
          className="absolute inset-0"
          title={title}
          onLoad={() => {
            // Ensure visible state is true when iframe is loaded
            setIsLoading(false);
            setIsVisible(true);
          }}
        />
      </div>
      
      {/* Gradient overlay for smoother visual integration */}
      <div 
        className={`absolute inset-0 pointer-events-none rounded-2xl transition-opacity duration-1000 ${isVisible ? 'opacity-0' : 'opacity-100'}`}
        style={{
          background: 'radial-gradient(circle at center, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%)'
        }}
      />
    </div>
  );
}; 