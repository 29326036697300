/**
 * Utility functions for device detection
 */

/**
 * Checks if the current device is a mobile device
 * @returns {boolean} True if the device is mobile, false otherwise
 */
export const isMobileDevice = (): boolean => {
  // Only run on client side
  if (typeof window === 'undefined') {
    return false;
  }
  
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
};

/**
 * Checks if the current viewport is mobile-sized
 * @param {number} breakpoint - The breakpoint width in pixels (default: 768)
 * @returns {boolean} True if the viewport width is less than the breakpoint
 */
export const isMobileViewport = (breakpoint = 768): boolean => {
  // Only run on client side
  if (typeof window === 'undefined') {
    return false;
  }
  
  return window.innerWidth < breakpoint;
};

/**
 * Hook to get the current device type
 * @returns {string} 'mobile' or 'desktop'
 */
export const getDeviceType = (): 'mobile' | 'desktop' => {
  return isMobileDevice() || isMobileViewport() ? 'mobile' : 'desktop';
};

/**
 * Combined function to check if the current device is mobile
 * @returns {boolean} True if the device is mobile (either by user agent or viewport)
 */
const isMobile = (): boolean => {
  return isMobileDevice() || isMobileViewport();
};

// Default export for backwards compatibility with code that imports from isMobile.ts
export default isMobile; 