export interface FooterItem {
  label: string;
  items: {
    label: string;
    href: string;
  }[];
}

export const footerItems: FooterItem[] = [
  {
    label: 'Information',
    items: [
      { label: 'Blog', href: '/blog' },
      { label: 'FAQ', href: '/faq' },
      { label: 'Audeze', href: 'https://www.audeze.com/blogs/audeze-artists/audeze-talks-with-mixing-and-mastering-engineer-giovanni-cordova' }
    ]
  },
  {
    label: 'Connect',
    items: [
      { label: 'Instagram', href: 'https://www.instagram.com/giovicordova' },
      { label: 'LinkedIn', href: 'https://www.linkedin.com/in/giovanni-cordova/' },
      { label: 'SoundBetter', href: 'https://soundbetter.com/profiles/334971-giovanni-cordova' },
      { label: 'EngineEars', href: 'https://engineears.com/gcordova' }
    ]
  }
]; 