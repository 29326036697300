/**
 * Utility functions for sharing content
 */

/**
 * Share the current URL on various platforms
 * @param platform - The platform to share on ('whatsapp', 'twitter', 'facebook', 'linkedin', or 'copy')
 * @returns Promise that resolves when the share is complete
 */
export const shareOnPlatform = async (platform: string): Promise<void> => {
  const url = window.location.href;
  
  switch (platform) {
    case 'whatsapp':
      window.open(`https://wa.me/?text=${encodeURIComponent(url)}`, '_blank');
      break;
    case 'twitter':
      window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`, '_blank');
      break;
    case 'facebook':
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`, '_blank');
      break;
    case 'linkedin':
      window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`, '_blank');
      break;
    case 'copy':
      try {
        await navigator.clipboard.writeText(url);
        // Could add a toast notification here
      } catch (err) {
        // Fallback for browsers that don't support clipboard API
        const textArea = document.createElement('textarea');
        textArea.value = url;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
      }
      break;
    default:
      // Handle unsupported platforms
      console.warn(`Sharing to ${platform} is not supported`);
  }
}; 