'use client';

import Link from 'next/link';
import Image from 'next/image';
import { NavItem, Section } from '@/constants/navigation';
import { useState } from 'react';
import { Share2, Facebook, Twitter, Copy, MessageCircle, Linkedin } from 'lucide-react';

interface DesktopNavProps {
  items: NavItem[];
  activeSection: Section;
  isHomePage: boolean;
  isBlogSection?: boolean;
  onHoverChange: (isHovering: boolean) => void;
}

export const DesktopNav = ({ 
  items, 
  activeSection,
  isHomePage,
  isBlogSection = false,
  onHoverChange 
}: DesktopNavProps) => {
  const [showShareMenu, setShowShareMenu] = useState(false);
  const [showCopyConfirmation, setShowCopyConfirmation] = useState(false);

  const handleShare = async (platform: string) => {
    const url = window.location.href;
    
    switch (platform) {
      case 'whatsapp':
        window.open(`https://wa.me/?text=${encodeURIComponent(url)}`, '_blank');
        break;
      case 'twitter':
        window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`, '_blank');
        break;
      case 'facebook':
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`, '_blank');
        break;
      case 'linkedin':
        window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`, '_blank');
        break;
      case 'copy':
        await navigator.clipboard.writeText(url);
        setShowCopyConfirmation(true);
        setTimeout(() => {
          setShowCopyConfirmation(false);
        }, 2000);
        break;
    }
  };

  return (
    <div className="hidden md:flex items-center justify-between w-full">
      <div className="relative w-1/4">
        <Link 
          href="/" 
          className="text-base tracking-normal font-bold text-white transition-all duration-[var(--animation-timing)]"
        >
          <Image
            src="/images/LOGO GC_1.png"
            alt="GC Logo"
            width={40}
            height={40}
            sizes="40px"
            className="object-contain brightness-0 invert contrast-200"
          />
        </Link>
      </div>

      <div className="flex items-center justify-center w-2/4">
        <div className="flex items-center justify-center space-x-8">
          {items.map((item) => (
            <div 
              key={item.href}
              className={`text-base tracking-normal font-normal transition-all duration-[var(--animation-timing)] relative group/nav-item
                ${activeSection === item.section ? 'text-white' : 'text-white/50 hover:text-white'}
                ${item.dropdown ? 'cursor-default' : 'cursor-pointer'}`}
              onMouseEnter={() => item.dropdown && onHoverChange(true)}
              onMouseLeave={() => item.dropdown && onHoverChange(false)}
            >
              {item.dropdown ? (
                <span className="transition-all duration-[var(--animation-timing)]">{item.label}</span>
              ) : (
                <Link href={item.href} className="flex items-center">
                  <span className="transition-all duration-[var(--animation-timing)]">{item.label}</span>
                  {item.label === "Pricing and Contact" && (
                    <span className="ml-1.5 w-1.5 h-1.5 rounded-full bg-orange-500 self-center flex-shrink-0" />
                  )}
                </Link>
              )}
              {item.dropdown && (
                <div className="absolute left-0 flex flex-col pt-3 z-[70] opacity-0 group-hover/nav-item:opacity-100 transition-opacity duration-[var(--animation-timing)]">
                  {item.dropdown.map((dropdownItem) => (
                    <Link
                      key={dropdownItem.href}
                      href={dropdownItem.href}
                      className="text-base tracking-normal font-normal text-white/50 transition-all duration-[var(--animation-timing)] hover:text-white whitespace-nowrap mt-3 first:mt-0 cursor-pointer"
                    >
                      {dropdownItem.label}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="w-1/4 flex justify-end">
        <div className="relative">
          {(isHomePage || isBlogSection) && (
            <button
              onClick={() => setShowShareMenu(!showShareMenu)}
              className="text-white/50 hover:text-white transition-all duration-[var(--animation-timing)]"
              aria-label="Share"
            >
              <Share2 size={20} />
            </button>
          )}
          
          {showShareMenu && (
            <div className="absolute right-0 mt-2 p-2 bg-[#1a1a1a] rounded-lg shadow-xl z-[70] flex gap-1">
              <button
                onClick={() => handleShare('whatsapp')}
                className="p-2 text-white/50 hover:text-white hover:bg-white/5 rounded-md transition-all duration-150"
                aria-label="Share on WhatsApp"
              >
                <MessageCircle size={18} />
              </button>
              <button
                onClick={() => handleShare('twitter')}
                className="p-2 text-white/50 hover:text-white hover:bg-white/5 rounded-md transition-all duration-150"
                aria-label="Share on Twitter"
              >
                <Twitter size={18} />
              </button>
              <button
                onClick={() => handleShare('facebook')}
                className="p-2 text-white/50 hover:text-white hover:bg-white/5 rounded-md transition-all duration-150"
                aria-label="Share on Facebook"
              >
                <Facebook size={18} />
              </button>
              <button
                onClick={() => handleShare('linkedin')}
                className="p-2 text-white/50 hover:text-white hover:bg-white/5 rounded-md transition-all duration-150"
                aria-label="Share on LinkedIn"
              >
                <Linkedin size={18} />
              </button>
              <button
                onClick={() => handleShare('copy')}
                className="p-2 text-white/50 hover:text-white hover:bg-white/5 rounded-md transition-all duration-150 relative"
                aria-label="Copy Link"
              >
                <Copy size={18} />
                {showCopyConfirmation && (
                  <div className="absolute -top-7 -left-6 bg-[#1a1a1a] text-white text-[10px] px-2 py-1 rounded whitespace-nowrap">
                    Link copied
                  </div>
                )}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}; 