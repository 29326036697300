export interface Review {
  id: string;
  author: string;
  text: string;
  rating: number;
  date?: string;
  verified?: boolean;
  country?: string;
}

export const reviews: Review[] = [
  {
    id: '1',
    author: 'Philippe M.',
    text: 'Very professional and modern mixing. Great job!',
    rating: 5,
    date: 'March 2024',
    verified: true,
    country: 'FR'
  },
  {
    id: '2',
    author: 'Philippe M.',
    text: 'Giovanni is very collaborative, he is a great professional!',
    rating: 5,
    date: 'March 2024',
    verified: true,
    country: 'FR'
  },
  {
    id: '3',
    author: 'Sean S.',
    text: 'Giovanni is a great communicator and has streamlined the process of revisions. Before starting the project he made sure that he had a clear vision of what my project would sound like and was open to my feedback. If you are looking for someone who is easy to work with and passionate about your project, he\'s a great pick.',
    rating: 5,
    date: 'February 2024',
    verified: true,
    country: 'US'
  },
  {
    id: '4',
    author: 'Corey',
    text: 'Gio is the best! Always pleasant to work with and the product always sounds great!',
    rating: 5,
    date: 'January 2024',
    verified: true,
    country: 'US'
  },
  {
    id: '5',
    author: 'theRave',
    text: 'This is my second time working with Giovanni and he is by far the most professional mastering engineer I\'ve ever worked with. Fantastic, professional-sounding results, wonderful communication, and an overall pleasure to work with because he REALLY CARES ABOUT YOUR MUSIC & ARTISTRY. Can\'t recommend him enough. Definitely hitting him up for more work in the future.',
    rating: 5,
    date: 'November 2023',
    verified: true,
    country: 'US'
  },
  {
    id: '6',
    author: 'Cindy K.',
    text: 'Giovanni Cordova is master of his craft. He took my poor, home-recorded tracks of my audiobook and turned them into something I am proud to give to listeners. The working experience was flawless, even across continents. He saved my dream.',
    rating: 5,
    date: 'September 2023',
    verified: true,
    country: 'US'
  },
  {
    id: '7',
    author: 'Cynthia',
    text: 'Giovanni was very professional and provided great service. He received feedback well and made sure all my requests were met. Very talented engineer!',
    rating: 5,
    date: 'August 2023',
    verified: true,
    country: 'US'
  },
  {
    id: '8',
    author: 'ALANKA',
    text: 'Working with Gio was an absolute pleasure! The entire process was streamlined/ hassle-free. Gio not only skillfully brought my track to life, perfectly capturing the vision I had in mind, but also ensured that the final product sounded utterly professional. Highly recommend for anyone looking to elevate their music to a professional level!',
    rating: 5,
    date: 'June 2023',
    verified: true,
    country: 'AE'
  },
  {
    id: '9',
    author: 'Sandeep S.',
    text: 'Loved working with Gio. I\'m new to music production and he was super helpful and accommodating. Loved how the final piece sounded as well.',
    rating: 5,
    date: 'June 2023',
    verified: true,
    country: 'IN'
  },
  {
    id: '10',
    author: 'Richard',
    text: 'Gio understood the requirements and delivered with great communication and most importantly, he provided some finishing touches that gave new perspectives! Thank you.',
    rating: 5,
    date: 'April 2023',
    verified: true
  },
  {
    id: '11',
    author: 'Philippe M.',
    text: 'Very professional mix!',
    rating: 5,
    date: 'March 2023',
    verified: true,
    country: 'FR'
  },
  {
    id: '12',
    author: 'Philippe M.',
    text: 'Another great song. Great sound!',
    rating: 5,
    date: 'March 2023',
    verified: true,
    country: 'FR'
  },
  {
    id: '13',
    author: 'Philippe M.',
    text: 'Great sound, broadcast ready.',
    rating: 5,
    date: 'February 2023',
    verified: true,
    country: 'FR'
  },
  {
    id: '14',
    author: 'Luis Miguel Ayala',
    text: 'Gio is my trusted expert, his passion and dedication to his craft is evident throughout the process. I can\'t recommend him highly enough – it\'s always a pleasure working with him.',
    rating: 5,
    date: 'February 2023',
    verified: true,
    country: 'MX'
  },
  {
    id: '15',
    author: 'Philippe M.',
    text: 'Another great mix!',
    rating: 5,
    date: 'January 2023',
    verified: true,
    country: 'FR'
  },
  {
    id: '16',
    author: 'Philippe M.',
    text: 'The best as usual!',
    rating: 5,
    date: 'January 2023',
    verified: true,
    country: 'FR'
  },
  {
    id: '17',
    author: 'theRave',
    text: 'Giovanni is wonderful to work with. For starters, he is VERY patient, which was a huge plus for me. I had mixes that his mastering work showed me weren\'t finished. Once they were, his mastering made them sound like a million bucks! The quality of his work is matched by his service, that is to say, it is all exceptional. He is a master at his craft and I look forward to working with him again!',
    rating: 5,
    date: 'December 2022',
    verified: true,
    country: 'US'
  },
  {
    id: '18',
    author: 'Philippe M.',
    text: 'Just great',
    rating: 5,
    date: 'December 2022',
    verified: true,
    country: 'FR'
  },
  {
    id: '19',
    author: 'Philippe M.',
    text: 'Giovanni is a very professional sound engineer. Always attentive and very coherent when producing an album.',
    rating: 5,
    date: 'November 2022',
    verified: true,
    country: 'FR'
  },
  {
    id: '20',
    author: 'Philippe M.',
    text: 'Giovanni is a top mixing engineer.',
    rating: 5,
    date: 'November 2022',
    verified: true,
    country: 'FR'
  },
  {
    id: '21',
    author: 'Alysha A.',
    text: 'This was my second time working with Giovanni and he killed it like always! He works very quick and is very easy to communicate with!',
    rating: 5,
    date: 'October 2022',
    verified: true,
    country: 'US'
  },
  {
    id: '22',
    author: 'Philippe M.',
    text: 'Excellent job! Very professional!',
    rating: 5,
    date: 'October 2022',
    verified: true,
    country: 'FR'
  },
  {
    id: '23',
    author: 'Philippe M.',
    text: 'Giovanni is an excellent mixer to work with! He understands your vision and brought the mix to life.',
    rating: 5,
    date: 'September 2022',
    verified: true,
    country: 'FR'
  },
  {
    id: '24',
    author: 'Philippe M.',
    text: 'Always a very good sound, clear and spatial',
    rating: 5,
    date: 'September 2022',
    verified: true,
    country: 'FR'
  },
  {
    id: '25',
    author: 'Philippe M.',
    text: 'Excellent! Top quality and Giovanni is also very collaborative, so he helps a lot.',
    rating: 5,
    date: 'August 2022',
    verified: true,
    country: 'FR'
  },
  {
    id: '26',
    author: 'Philippe M.',
    text: 'Excellent sound quality, Giovanni never gives up until he finds the high-end sound. A great engineer.',
    rating: 5,
    date: 'August 2022',
    verified: true,
    country: 'FR'
  },
  {
    id: '27',
    author: 'Philippe M',
    text: 'I worked with many sound engineers and I could never get a sound of such quality. Bravo',
    rating: 5,
    date: 'July 2022',
    verified: true,
    country: 'FR'
  },
  {
    id: '28',
    author: 'Philippe M.',
    text: 'Once again an excellent job. Giovanni is a phenomenal engineer. Just perfect.',
    rating: 5,
    date: 'July 2022',
    verified: true,
    country: 'FR'
  },
  {
    id: '29',
    author: 'Philippe M.',
    text: 'Once again, great work! Giovanni makes your song easy to listen to with very high sound quality. Cheer!',
    rating: 5,
    date: 'June 2022',
    verified: true,
    country: 'FR'
  },
  {
    id: '30',
    author: 'Philippe M.',
    text: 'Extremely precise in his choices, a sound of absolute clarity. Easy listening tunes, even hard rock goes mainstream.',
    rating: 5,
    date: 'June 2022',
    verified: true,
    country: 'FR'
  },
  {
    id: '31',
    author: 'Jules',
    text: '10/10 Amazinggg at making your song the best it can be. Giovanni takes feedback super well and will make you fall in love with your song alll over again!',
    rating: 5,
    date: 'May 2022',
    verified: true,
    country: 'US'
  },
  {
    id: '32',
    author: 'Ray F.',
    text: 'Gio never ceases to astound me with his perfect ear and mixing skills!!',
    rating: 5,
    date: 'May 2022',
    verified: true,
    country: 'AU'
  },
  {
    id: '33',
    author: 'Sven P.',
    text: 'Working with Gio was a beautiful experience. He has an excellent work ethic, coming back with revisions quickly and hitting all the outlined points. So you can be sure that your song will be shaped until you are 100% satisfied. Highly recommend working with him, as I will be again as well.',
    rating: 5,
    date: 'April 忖 2022',
    verified: true,
    country: 'DE'
  },
  {
    id: '34',
    author: 'Tank M.',
    text: 'GIO is my favorite engineer. He knows how to make your song sound fresh and lit.',
    rating: 5,
    date: 'April 2022',
    verified: true
  },
  {
    id: '35',
    author: 'Ray F.',
    text: 'Gio is both a wonderful engineer and a kind human being with a magical touch. He has outstanding attention to detail and he is really able to polish up elements to the most minor detail!',
    rating: 5,
    date: 'March 2022',
    verified: true,
    country: 'AU'
  },
  {
    id: '36',
    author: 'Doede B.',
    text: 'Splendid job again, delivered very quickly. Masters are of high quality. Definitely recommend to work with this down to earth lad.',
    rating: 5,
    date: 'March 2022',
    verified: true,
    country: 'NL'
  },
  {
    id: '37',
    author: 'Luismi M.',
    text: '10/10 would recommend! Gio is not only a talented audio engineer but a true professional. He communicated with me effectively, cared and worked on every detail I requested.',
    rating: 5,
    date: 'February 2022',
    verified: true,
    country: 'MX'
  },
  {
    id: '38',
    author: 'Azriah Minovi',
    text: 'Giovanni is such a talent friendly communicative guy and that comes across in working with him for mixing/mastering. He was open to all my input, responded and kept me in the loop as the song was being mixed and mastered. It felt like a completely tailored experience incorporating my vision/direction for the song and Gios superstar amazing sounding mixing/mastering. Very impressed, cant recommend enough!',
    rating: 5,
    date: 'February 2022',
    verified: true
  },
  {
    id: '39',
    author: 'Peter Cloud',
    text: 'Outstanding Mix & Mastering !! Giovanni is definitely the best mixing engineer on this platform. If you want good communication & fast & amazing work to be done, he is the right guy !!',
    rating: 5,
    date: 'January 2022',
    verified: true,
    country: 'IT'
  },
  {
    id: '40',
    author: 'Corey',
    text: 'If you have your heart set on mixing your own songs and aren\'t professional, but still want it to sound professional even if you aren\'t; then Giovanni\'s Stem Mastering is the way to go! He was able to keep and elevate everything that I loved about my mix while fixing all of the problematic aspects. Highly Recommended!',
    rating: 5,
    date: 'January 2022',
    verified: true,
    country: 'US'
  },
  {
    id: '41',
    author: 'Ray F.',
    text: 'I feel this is the start of a great partnership, blown away yet again with Gio\'s mixing skills. The final master sounded so much better than I ever hoped - it made my production sound so much better in ways I couldn\'t have expected or predicted! Brilliant service and a top guy!',
    rating: 5,
    date: 'December 2021',
    verified: true,
    country: 'AU'
  },
  {
    id: '42',
    author: 'Rod\'s R.',
    text: 'Giovanni was very good .. paying attention to all the details A Really professional sound engineer and An amazing Sound quality..fast and efficient !! I\'m very happy to have work with him for mix and master my song !!',
    rating: 5,
    date: 'December 2021',
    verified: true,
    country: 'IT'
  },
  {
    id: '43',
    author: 'Doede B.',
    text: 'Fun to work with it. Very professional and reliable. Tracks that were delivered are of high quality. Overall, very down to earth person to work with it.',
    rating: 5,
    date: 'November 2021',
    verified: true
  },
  {
    id: '44',
    author: 'Peter Cloud',
    text: 'Fast delivery; high quality job. Definitely to recommend this guy :) !!',
    rating: 5,
    date: 'November 2021',
    verified: true,
    country: 'IT'
  },
  {
    id: '45',
    author: 'Lovro B.',
    text: 'Quick and efficient as always.',
    rating: 5,
    date: 'October 2021',
    verified: true,
    country: 'HR'
  },
  {
    id: '46',
    author: 'Oliver S.',
    text: 'Perfect Job - everything so professional.',
    rating: 5,
    date: 'October 2021',
    verified: true,
    country: 'AT'
  },
  {
    id: '47',
    author: 'Klaus',
    text: 'Giovanni was recommended to me during the lockdown period and I thought, yeah, why not giving a try. And I have to say, I did not regret it, very structured work, clear and quick communication, not too proud to react to suggestions or sound adjustments. Every review happened quickly and as expected. At the end we had a master everybody was satisfied with!',
    rating: 5,
    date: 'September 2021',
    verified: true,
    country: 'AT'
  },
  {
    id: '48',
    author: 'Christel Clear',
    text: 'I rely on Giovanni for his punctuality and precision, he is always available and fast in deliveries, now thanks to him my songs sound with a quality just like the Radio Hits',
    rating: 5,
    date: 'September 2021',
    verified: true,
    country: 'IT'
  },
  {
    id: '49',
    author: 'Andrea S.',
    text: 'Professional and quality! Recommended 100% for a great mastering!!',
    rating: 5,
    date: 'August 2021',
    verified: true,
    country: 'IT'
  },
  {
    id: '50',
    author: 'Rino B.',
    text: 'I had the pleasure of working with Giovanni at "La Macina Sonora" Studio for Drums and Vocal recordings. He was really professional, precise and the result of his mixing process was amazing. Recommended.',
    rating: 5,
    date: 'August 2021',
    verified: true,
    country: 'IT'
  },
  {
    id: '51',
    author: 'Romel O.',
    text: 'He\'s really good! trust me!!!',
    rating: 5,
    date: 'February 2021',
    verified: true
  },
  {
    id: '52',
    author: 'molotof m.',
    text: 'great quality of work and communication.\nthank you!',
    rating: 5,
    date: 'February 2021',
    verified: true,
    country: 'EG'
  },
  {
    id: '53',
    author: 'Alberto G.',
    text: 'professionale e molto disponibile, una sicurezza',
    rating: 5,
    date: 'January 2021',
    verified: true,
    country: 'IT'
  },
  {
    id: '54',
    author: 'Dane M.',
    text: 'Super professional, quick and caring.',
    rating: 5,
    date: 'January 2021',
    verified: true,
    country: 'US'
  },
  {
    id: '55',
    author: 'Amaro',
    text: 'Professional and fast. Definitely recommended!',
    rating: 5,
    date: 'December 2020',
    verified: true,
    country: 'IT'
  },
  {
    id: '56',
    author: 'Roberto Cogno',
    text: 'I had the pleasure of working with Giovanni on several projects and the passion and professionalism he brings to his work are absolutely remarkable!\nFantastic producer, engineer and very nice guy!\nRecommended',
    rating: 5,
    date: 'December 2020',
    verified: true,
    country: 'IT'
  },
  {
    id: '57',
    author: 'Paolo Ilario Pezzotti',
    text: 'Professional, serious, efficient, skilled, accurate and creative',
    rating: 5,
    date: 'November 2020',
    verified: true,
    country: 'IT'
  },
  {
    id: '58',
    author: 'Kevin M.',
    text: 'Giovanni did a great job mixing and mastering my songs! He responded promptly and delivered great results after I gave feedback.',
    rating: 5,
    date: 'November 2020',
    verified: true
  },
  {
    id: '59',
    author: 'Lovro',
    text: 'I collaborated with Giovanni on a few projects so far, great guy to work with and easy to communicate with. More so, he\'s a great mixing and mastering engineer, I was really happy with the results.',
    rating: 5,
    date: 'October 2020',
    verified: true,
    country: 'HR'
  },
  {
    id: '60',
    author: 'Peter Cloud',
    text: 'Extraordinary to work with him !! Quality work big time !! Worked with him a lot of times, really gets the job done\nReally recommending him.',
    rating: 5,
    date: 'October 2020',
    verified: true,
    country: 'IT'
  },
  {
    id: '61',
    author: 'Lovro B.',
    text: 'Professional, quick and caring. Great mastering results!',
    rating: 5,
    date: 'September 2020',
    verified: true,
    country: 'HR'
  },
  {
    id: '62',
    author: 'Ray F.',
    text: 'Gio is a superstar sound engineer - highly qualified and displays huge amounts of passion in his work. From the big-picture all the way down to the details, every part of the song is mixed and mastered to perfection.\nHighly recommend!',
    rating: 5,
    date: 'September 2020',
    verified: true,
    country: 'AU'
  },
  {
    id: '63',
    author: 'Ray F.',
    text: 'I only have great things to say about Gio! His high-level expertise helps me take each track to its best potential!! Couldn\'t recommend anyone else',
    rating: 5,
    date: 'August 2020',
    verified: true,
    country: 'AU'
  },
  {
    id: '64',
    author: 'Ray F.',
    text: 'Gio never ceases to amaze me with the highest quality mixing and mastering skills. He is not only professionally qualified to take your song to the next level but has the ear and passion to match and exceed expectations :)',
    rating: 5,
    date: 'August 2020',
    verified: true,
    country: 'AU'
  },
  {
    id: '65',
    author: 'Ray F.',
    text: 'Gio did a perfect mix & master which took my track to the next level. His service and product is of the highest quality and to an industry standard!',
    rating: 5,
    date: 'July 2020',
    verified: true,
    country: 'AU'
  },
  {
    id: '66',
    author: 'Ray F.',
    text: 'Gio went way above and beyond with this! He really put in so much effort and I couldn\'t be happier with how it turned out. Would highly recommend to anyone looking for someone with initiative, expertise and to exceed their expectations!',
    rating: 5,
    date: 'July 2020',
    verified: true,
    country: 'AU'
  },
  {
    id: '67',
    author: 'Tank M.',
    text: 'One word: OUTSTANDING!',
    rating: 5,
    date: 'June 2020',
    verified: true
  },
  {
    id: '68',
    author: 'Ray F.',
    text: 'Gio has the perfect ear when it comes to mixing and mastering! Vocal mixing is one of the best I\'ve heard.',
    rating: 5,
    date: 'June 2020',
    verified: true,
    country: 'AU'
  },
  {
    id: '69',
    author: 'Jules',
    text: 'Giovanni is the sweetest and will send you as many versions as you need until its perfect.',
    rating: 5,
    date: 'May 2020',
    verified: true,
    country: 'US'
  },
  {
    id: '70',
    author: 'Ray F.',
    text: 'Brilliance as always from Gio!',
    rating: 5,
    date: 'May 2020',
    verified: true,
    country: 'AU'
  },
  {
    id: '71',
    author: 'Ray F.',
    text: 'Wow Gio well and truly exceeded my expectations again. One of the best music professionals on Soundbetter!',
    rating: 5,
    date: 'April 2020',
    verified: true,
    country: 'AU'
  },
  {
    id: '72',
    author: 'Ray F.',
    text: 'I never have any worries working with Gio, he always delivers the best!',
    rating: 5,
    date: 'April 2020',
    verified: true,
    country: 'AU'
  },
  {
    id: '73',
    author: 'Ray F.',
    text: 'Gio is always a superstar to work with!! If you\'re looking for the best sounding mix/master then he is your man!',
    rating: 5,
    date: 'March 2020',
    verified: true,
    country: 'AU'
  },
  {
    id: '74',
    author: 'Ray F.',
    text: 'Gio really puts in the time and effort into making a record shine. There are certain details that he is able to execute brilliantly due to his high qualifications and trained ear!',
    rating: 5,
    date: 'March 2020',
    verified: true,
    country: 'AU'
  },
  {
    id: '75',
    author: 'Corey',
    text: 'Fantastic experience with Giovanni on this project. Sad that this is the last song!',
    rating: 5,
    date: 'February 2020',
    verified: true,
    country: 'US'
  },
  {
    id: '76',
    author: 'Corey',
    text: 'Such a great experience working with Giovanni! The song sounds fantastic and the interactions were seemless!',
    rating: 5,
    date: 'February 2020',
    verified: true,
    country: 'US'
  },
  {
    id: '77',
    author: 'Corey',
    text: 'Another flawless job!',
    rating: 5,
    date: 'January 2020',
    verified: true,
    country: 'US'
  },
  {
    id: '78',
    author: 'Corey',
    text: 'Exceptional sounding product! It\'s a pleasure working with Giovanni!',
    rating: 5,
    date: 'January 2020',
    verified: true,
    country: 'US'
  },
  {
    id: '79',
    author: 'Corey',
    text: 'Excellent product and excellent experience! I highly recommend working with Giovanni for your project! You won\'t be disappointed!',
    rating: 5,
    date: 'December 2019',
    verified: true,
    country: 'US'
  },
  {
    id: '80',
    author: 'Corey',
    text: 'Working with Giovanni has been so smooth! Great Experience!',
    rating: 5,
    date: 'December 2019',
    verified: true,
    country: 'US'
  },
  {
    id: '81',
    author: 'Corey',
    text: 'It feels good to know my project is in great hands with Giovanni! Can\'t wait to hear how the rest of it sounds when he finishes!',
    rating: 5,
    date: 'November 2019',
    verified: true,
    country: 'US'
  },
  {
    id: '82',
    author: 'Jules',
    text: 'As always, Gio is the best and most receptive mixing engineer I have worked with. He always leaves me in awe with his quality mixes',
    rating: 5,
    date: 'November 2019',
    verified: true,
    country: 'US'
  },
  {
    id: '83',
    author: 'Corey',
    text: 'Master sounded fantastic! Giovanni was very easy to work with and got all my needed changes spot on the first time!',
    rating: 5,
    date: 'October 2019',
    verified: true,
    country: 'US'
  },
  {
    id: '84',
    author: 'Ray F.',
    text: 'I have huge trust in Gio\'s work, this time we worked on four records together and wow did he bring out the best in them!!! :)',
    rating: 5,
    date: 'October 2019',
    verified: true,
    country: 'AU'
  }
]; 