import { MEDIA } from '@/constants/media';

export const content = {
  title: 'Mixing and Mastering',
  subtitle: 'Professional Digital and Analog Sonic Enhancement, Global Distribution Ready',
  paragraphs: [
    `Professional mixing and mastering services that transform raw recordings into radio-ready releases. With industry-standard equipment and over a decade of experience, I deliver clear, balanced, and impactful sound that connects with listeners while preserving your music's unique character and emotion.`,
    `Hi, I'm Giovanni Cordova, and I'm here to help your music reach its full potential. Over the past decade, I've helped hundreds of artists bring their songs to life, including features on Spotify's New Music Friday. What matters most is maintaining the emotional core of your music while helping it sound professional.`,
    `I understand that every song carries your story, and my role is to help that story connect with listeners. Whether you're crafting pop, Latin, rock, or any genre, I'll work with you to achieve a sound that's both clear and compelling.`
  ],
  spotifyPlaylistUrl: 'https://open.spotify.com/embed/playlist/5cFUYTfH0gHhYP8DsgptAU'
};