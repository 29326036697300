interface Color {
  r: number;
  g: number;
  b: number;
}

export const getAverageColor = async (imageUrl: string): Promise<string> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      
      if (!context) {
        resolve('#0A0A0A'); // Fallback color
        return;
      }

      // Draw image to canvas
      canvas.width = img.width;
      canvas.height = img.height;
      context.drawImage(img, 0, 0);

      // Get image data
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height).data;
      
      let r = 0, g = 0, b = 0;
      const total = imageData.length / 4; // Each pixel has 4 values (r,g,b,a)

      // Calculate sum of all colors
      for (let i = 0; i < imageData.length; i += 4) {
        r += imageData[i];
        g += imageData[i + 1];
        b += imageData[i + 2];
      }

      // Calculate average
      r = Math.floor(r / total);
      g = Math.floor(g / total);
      b = Math.floor(b / total);

      // Darken the color for better contrast
      r = Math.floor(r * 0.1);
      g = Math.floor(g * 0.1);
      b = Math.floor(b * 0.1);

      resolve(`rgb(${r}, ${g}, ${b})`);
    };

    img.onerror = () => {
      resolve('#0A0A0A'); // Fallback color
    };

    img.src = imageUrl;
  });
};

export const extractDominantColors = async (imageUrl: string): Promise<string> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      
      if (!context) {
        resolve('radial-gradient(circle at 50% 50%, rgba(10,10,10,0.95), rgba(10,10,10,0.98))');
        return;
      }

      canvas.width = img.width;
      canvas.height = img.height;
      context.drawImage(img, 0, 0);

      const imageData = context.getImageData(0, 0, canvas.width, canvas.height).data;
      const colors: Color[] = [];
      
      // Sample pixels at strategic points for better color representation
      const samplePoints = [
        { x: 0, y: 0 },                    // Top-left
        { x: canvas.width / 2, y: 0 },     // Top-center
        { x: canvas.width - 1, y: 0 },     // Top-right
        { x: 0, y: canvas.height / 2 },    // Middle-left
        { x: canvas.width / 2, y: canvas.height / 2 }, // Center
        { x: canvas.width - 1, y: canvas.height / 2 }, // Middle-right
        { x: 0, y: canvas.height - 1 },    // Bottom-left
        { x: canvas.width / 2, y: canvas.height - 1 }, // Bottom-center
        { x: canvas.width - 1, y: canvas.height - 1 }  // Bottom-right
      ];

      samplePoints.forEach(point => {
        const x = Math.floor(point.x);
        const y = Math.floor(point.y);
        const index = (y * canvas.width + x) * 4;
        colors.push({
          r: imageData[index],
          g: imageData[index + 1],
          b: imageData[index + 2]
        });
      });

      // Filter out similar colors and get the most distinct ones
      const distinctColors = colors.reduce((acc, color) => {
        const isDifferent = acc.every(existingColor => {
          const diff = Math.abs(color.r - existingColor.r) + 
                      Math.abs(color.g - existingColor.g) + 
                      Math.abs(color.b - existingColor.b);
          return diff > 100; // Threshold for color difference
        });
        if (isDifferent) acc.push(color);
        return acc;
      }, [] as Color[]);

      // Create gradient with the most distinct colors
      const gradientColors = distinctColors.slice(0, 3).map(color => {
        const factor = 0.15; // Darken to 15% of original brightness
        return `rgba(${Math.floor(color.r * factor)}, ${Math.floor(color.g * factor)}, ${Math.floor(color.b * factor)}, 0.98)`;
      });

      // Ensure we have at least 3 colors
      while (gradientColors.length < 3) {
        gradientColors.push('rgba(10,10,10,0.98)');
      }

      // Create a three-color gradient for smoother transitions
      const gradient = `radial-gradient(circle at 50% 50%, ${gradientColors.join(', ')})`;
      
      resolve(gradient);
    };

    img.onerror = () => {
      resolve('radial-gradient(circle at 50% 50%, rgba(10,10,10,0.95), rgba(10,10,10,0.98))');
    };

    img.src = imageUrl;
  });
}; 