import React from 'react';

interface SeoWrapperProps {
  children: React.ReactNode;
  itemType?: string;
  itemProp?: string;
  itemId?: string;
  keywords?: string[];
  className?: string;
  [key: string]: any;
}

/**
 * Wraps content with semantic SEO attributes without changing visual appearance
 */
export const SeoWrapper: React.FC<SeoWrapperProps> = ({ 
  children, 
  itemType, 
  itemProp, 
  itemId,
  keywords,
  className = "",
  ...props 
}) => {
  return (
    <div 
      itemScope={!!itemType} 
      itemType={itemType ? `https://schema.org/${itemType}` : undefined}
      itemProp={itemProp}
      id={itemId}
      data-keywords={keywords?.join(',')}
      className={className}
      {...props}
    >
      {children}
    </div>
  );
};

export default SeoWrapper; 