export type Section = 'home' | 'explore' | 'before-after' | 'gallery' | 'reviews' | 'contact' | 'faq' | 'blog';

export interface NavItem {
  label: string;
  href: string;
  section: Section;
  dropdown?: NavItem[];
}

export const navigationItems: NavItem[] = [
  {
    label: 'Explore',
    href: '/explore',
    section: 'explore',
    dropdown: [
      {
        label: 'Before and After',
        href: '/before-after',
        section: 'before-after'
      },
      {
        label: 'Mixing and Mastering',
        href: '/explore/mixing-and-mastering',
        section: 'explore'
      },
      {
        label: 'Podcasts and Audiobooks',
        href: '/explore/podcasts-and-audiobooks',
        section: 'explore'
      }
    ]
  },
  {
    label: 'Gallery',
    href: '/gallery',
    section: 'gallery'
  },
  {
    label: 'Reviews',
    href: '/reviews',
    section: 'reviews'
  },
  {
    label: 'Pricing and Contact',
    href: '/contact',
    section: 'contact'
  }
]; 