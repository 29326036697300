'use client';

import React from 'react';
import Link from 'next/link';
import { useLanguage } from '@/contexts/LanguageContext';
import BasicImage from '@/components/BasicImage';

export interface LanguageSwitchProps {
  mobile?: boolean;
}

export default function LanguageSwitch({ mobile = false }: LanguageSwitchProps) {
  const { language, setLanguage } = useLanguage();
  
  // For the vocal-processing article only
  const switchPath = language === 'en' ? '/es' : '/';

  const handleLanguageChange = (e: React.MouseEvent) => {
    e.preventDefault();
    setLanguage(language === 'en' ? 'es' : 'en');
  };

  // Flag version for both mobile and desktop
  return (
    <button
      onClick={handleLanguageChange}
      className={`px-2 py-1 rounded bg-white/5 hover:bg-white/10 text-white/90 hover:text-white flex items-center transition-colors ${mobile ? 'scale-90' : ''}`}
      aria-label={`Switch to ${language === 'en' ? 'Spanish' : 'English'}`}
    >
      <div className="flex items-center gap-2">
        <div className={`h-6 w-6 relative rounded-full overflow-hidden ${language === 'en' ? 'opacity-100' : 'opacity-40'}`}>
          <img
            src="/images/flags/UK.jpg" 
            alt="English" 
            className="w-full h-full object-cover"
          />
        </div>
        
        <div className={`h-6 w-6 relative rounded-full overflow-hidden ${language === 'es' ? 'opacity-100' : 'opacity-40'}`}>
          <img
            src="/images/flags/ESP.jpg" 
            alt="Spanish" 
            className="w-full h-full object-cover"
          />
        </div>
      </div>
    </button>
  );
} 