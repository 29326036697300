'use client';

import Link from 'next/link';
import { footerItems } from '@/constants/footer';
import { BackToTop } from './BackToTop';

export default function Footer() {
  return (
    <footer className="relative w-full z-[100]">
      <nav className="relative">
        <div className="w-[90%] max-w-[var(--container-width)] mx-auto">
          <div className="flex items-center">
            {/* Left side - Back to Top */}
            <div className="w-[100px]">
              <BackToTop />
            </div>

            {/* Middle - Navigation Links */}
            <div className="flex-1 flex justify-center gap-20">
              {/* Connect Section */}
              <div className="flex items-center gap-6">
                <span className="text-base font-bold text-white">Connect</span>
                {footerItems
                  .find(section => section.label === 'Connect')
                  ?.items.map((item) => (
                    <Link
                      key={item.label}
                      href={item.href}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-base text-white/50 hover:text-white transition-all duration-[var(--animation-timing)]"
                    >
                      {item.label}
                    </Link>
                  ))}
              </div>

              {/* Information Section */}
              <div className="flex items-center gap-6">
                <span className="text-base font-bold text-white">Information</span>
                {footerItems
                  .find(section => section.label === 'Information')
                  ?.items.map((item) => (
                    <Link
                      key={item.label}
                      href={item.href}
                      target={item.href.startsWith('/') ? undefined : "_blank"}
                      rel={item.href.startsWith('/') ? undefined : "noopener noreferrer"}
                      className="text-base text-white/50 hover:text-white transition-all duration-[var(--animation-timing)]"
                    >
                      {item.label}
                    </Link>
                  ))}
              </div>
            </div>

            {/* Right side - Spacer */}
            <div className="w-[100px]" />
          </div>
        </div>
      </nav>
    </footer>
  );
} 