/**
 * Generates schema.org structured data for various content types
 */

interface ServiceSchema {
  title: string;
  description: string;
  type: string;
  url: string;
  imageUrl?: string;
  provider?: {
    name: string;
    url: string;
  };
  offers?: Array<{
    name: string;
    description: string;
    price?: string;
    currency?: string;
  }>;
}

export function generateServiceSchema(service: ServiceSchema) {
  return {
    "@context": "https://schema.org",
    "@type": "Service",
    "name": service.title,
    "description": service.description,
    "url": service.url,
    "provider": {
      "@type": "Person",
      "name": service.provider?.name || "Giovanni Cordova",
      "@id": "https://www.giovannicordova.com/#person",
      "url": service.provider?.url || "https://www.giovannicordova.com"
    },
    "serviceType": service.type,
    ...(service.imageUrl ? { "image": service.imageUrl } : {}),
    ...(service.offers && service.offers.length > 0 ? {
      "offers": service.offers.map(offer => ({
        "@type": "Offer",
        "name": offer.name,
        "description": offer.description,
        ...(offer.price && offer.currency ? {
          "price": offer.price,
          "priceCurrency": offer.currency
        } : {})
      }))
    } : {})
  };
}

interface PersonSchema {
  name: string;
  description: string;
  url: string;
  imageUrl: string;
  jobTitle: string;
  sameAs?: string[];
}

export function generatePersonSchema(person: PersonSchema) {
  return {
    "@context": "https://schema.org",
    "@type": "Person",
    "@id": "https://www.giovannicordova.com/#person",
    "name": person.name,
    "description": person.description,
    "url": person.url,
    "image": person.imageUrl,
    "jobTitle": person.jobTitle,
    "sameAs": person.sameAs || [],
    "worksFor": {
      "@type": "Organization",
      "name": person.name,
      "url": person.url
    }
  };
}

interface ReviewCollectionSchema {
  serviceName: string;
  serviceDescription: string;
  serviceUrl: string;
  serviceImage?: string;
  reviews: Array<{
    author: string;
    datePublished: string;
    reviewBody: string;
    ratingValue: number;
  }>;
}

export function generateReviewCollectionSchema(reviewData: ReviewCollectionSchema) {
  // Calculate average rating
  const ratings = reviewData.reviews.map(review => review.ratingValue);
  const averageRating = ratings.reduce((sum, rating) => sum + rating, 0) / ratings.length;
  
  return {
    "@context": "https://schema.org",
    "@type": "Product",
    "name": reviewData.serviceName,
    "description": reviewData.serviceDescription,
    "url": reviewData.serviceUrl,
    ...(reviewData.serviceImage ? { "image": reviewData.serviceImage } : {}),
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": averageRating.toFixed(1),
      "reviewCount": reviewData.reviews.length,
      "bestRating": "5"
    },
    "review": reviewData.reviews.map(review => ({
      "@type": "Review",
      "author": {
        "@type": "Person",
        "name": review.author
      },
      "datePublished": review.datePublished,
      "reviewBody": review.reviewBody,
      "reviewRating": {
        "@type": "Rating",
        "ratingValue": review.ratingValue,
        "bestRating": 5
      }
    }))
  };
}

interface FAQSchema {
  items: Array<{
    question: string;
    answer: string;
  }>;
}

export function generateFAQSchema(faqData: FAQSchema) {
  return {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": faqData.items.map(item => ({
      "@type": "Question",
      "name": item.question,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": item.answer
      }
    }))
  };
}

interface BreadcrumbSchema {
  items: Array<{
    name: string;
    url: string;
  }>;
}

export function generateBreadcrumbSchema(breadcrumbData: BreadcrumbSchema) {
  return {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": breadcrumbData.items.map((item, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "name": item.name,
      "item": item.url
    }))
  };
} 