'use client';

import React, { useState } from 'react';
import Image from 'next/image';
import MobileHeader from '@/components/MobileHeader';
import Link from 'next/link';
import Head from 'next/head';

export default function MobileGalleryPage() {
  const aspImages = [
    '/images/gallery/giovanni-cordova-studio-mixing-mastering-grammy-listening-recording-audio-professional-2025-13.jpg',
    '/images/gallery/giovanni-cordova-studio-mixing-mastering-outboard-analog-1176-ln-1178-compressor-2025-11.jpg',
    '/images/gallery/giovanni-cordova-studio-vocal-recording-james-herrera-ssl-g-desk-2025-02.jpg',
    '/images/gallery/giovanni-cordova-studio-mixing-mastering-professional-audio-engineer-producer-2025-15.jpg',
    '/images/gallery/giovanni-cordova-studio-mixing-ssl-lexicon-vintage-desk-knobs-2025-04.jpg',
    '/images/gallery/giovanni-cordova-studio-mixing-mastering-specialist-studio-analog-gear-analog-tape-2025-14.jpg',
  ];

  const crImages = [
    '/images/gallery/giovanni-cordova-studio-best-mixing-mastering-neve-desk-london-2025-06.jpg',
    '/images/gallery/giovanni-cordova-studio-best-mixing-mastering-analog-tape-london-2025-07.jpg',
    '/images/gallery/giovanni-cordova-studio-professional-mixing-mastering-vintage-equipment-london-2025-08.jpg',
  ];

  return (
    <>
      <Head>
        <link rel="canonical" href="https://www.giovannicordova.com/gallery" />
      </Head>
      
      <MobileHeader />
      <main className="min-h-screen bg-[#0a0a0a] pt-8 pb-12 space-y-8">
        {/* Page Title with static gradient effect */}
        <div className="w-full text-center mb-8">
          <h1 className="text-4xl font-medium text-static-gradient">
            Gallery
          </h1>
        </div>
        
        {/* Mobile-optimized gallery section */}
        <div className="w-[92%] mx-auto relative">
          <div className="mb-3 px-3 py-2 bg-[#0A0A0A]/90 text-[11px] font-light rounded-md">
            <div className="flex items-center">
              <div className="w-4 h-4 rounded-full overflow-hidden relative mr-2 flex-shrink-0">
                <div className="absolute inset-0 bg-[#009246] w-1/3" />
                <div className="absolute inset-0 left-1/3 bg-white w-1/3" />
                <div className="absolute inset-0 left-2/3 bg-[#CE2B37] w-1/3" />
              </div>
              <span className="text-white whitespace-nowrap">Ritmo & Blu Studio, Mid 2024.</span>
              <span className="text-white/50 font-light ml-1">
                Pictures by{' '}
                <Link href="https://www.andreas-senoner.com/en/" target="_blank" rel="noopener noreferrer" className="text-white hover:opacity-80">
                  Andreas Senoner
                </Link>
              </span>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-3">
            {aspImages.map((src, index) => (
              <div 
                key={index} 
                className="relative w-full aspect-[4/3] bg-neutral-900 rounded-lg overflow-hidden"
              >
                <Image
                  src={src}
                  alt={`ASP Gallery Image ${index + 1}`}
                  fill
                  className="object-cover"
                  sizes="92vw"
                  priority={index < 2}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Second gallery section with fixed UK flag */}
        <div className="w-[92%] mx-auto relative">
          <div className="mb-3 px-3 py-2 bg-[#0A0A0A]/90 text-[11px] font-light rounded-md">
            <div className="flex items-center">
              <div className="w-4 h-4 rounded-full overflow-hidden relative mr-2 flex-shrink-0 border border-white/20 flex items-center justify-center">
                <svg viewBox="0 0 60 30" className="w-4 h-4 absolute" preserveAspectRatio="xMidYMid slice">
                  <rect width="60" height="30" fill="#012169"/>
                  <path d="M0,0 L60,30 M60,0 L0,30" stroke="#fff" strokeWidth="6"/>
                  <path d="M0,0 L60,30 M60,0 L0,30" stroke="#C8102E" strokeWidth="4"/>
                  <path d="M30,0 v30 M0,15 h60" stroke="#fff" strokeWidth="10"/>
                  <path d="M30,0 v30 M0,15 h60" stroke="#C8102E" strokeWidth="6"/>
                </svg>
              </div>
              <span className="text-white whitespace-nowrap">London, Early 2024.</span>
              <span className="text-white/50 font-light ml-1">
                Pictures by{' '}
                <Link href="https://www.instagram.com/mario_simanovsky/" target="_blank" rel="noopener noreferrer" className="text-white hover:opacity-80">
                  Mario Simanovsky
                </Link>
              </span>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-3">
            {crImages.map((src, index) => (
              <div 
                key={index} 
                className="relative w-full aspect-[4/3] bg-neutral-900 rounded-lg overflow-hidden"
              >
                <Image
                  src={src}
                  alt={`CR Gallery Image ${index + 1}`}
                  fill
                  className="object-cover"
                  sizes="92vw"
                  priority={index < 2}
                />
              </div>
            ))}
          </div>
        </div>
        
        {/* Instagram link */}
        <div className="w-full text-center text-lg pb-4 pt-2">
          <span className="text-white/80 font-light">Let's connect</span>
          <span className="mx-2 text-2xl text-white">→</span>
          <Link 
            href="https://www.instagram.com/giovicordova/" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="relative inline-block text-white font-bold after:content-[''] after:absolute after:w-full after:h-[1px] after:bg-white after:left-0 after:bottom-0 after:origin-left after:scale-x-0 hover:after:scale-x-100 after:transition-transform after:duration-300"
          >
            Instagram
          </Link>
        </div>
      </main>
    </>
  );
} 