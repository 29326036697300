import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Facebook, Twitter, Copy, MessageCircle, Linkedin } from 'lucide-react';

// Define shared animation settings for consistency
const TRANSITION = {
  duration: 0.2,
  ease: [0.16, 1, 0.3, 1]
};

interface ShareMenuProps {
  showShareMenu: boolean;
  setShowShareMenu: (show: boolean) => void;
  handleShare: (platform: string) => void;
}

export default function ShareMenu({ showShareMenu, setShowShareMenu, handleShare }: ShareMenuProps) {
  const [showCopyConfirmation, setShowCopyConfirmation] = useState(false);

  // Handle backdrop click
  const handleBackdropClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent event bubbling
    setShowShareMenu(false);
  };

  // Handle share button click
  const handleShareClick = (e: React.MouseEvent, platform: string) => {
    e.stopPropagation(); // Prevent event bubbling
    
    if (platform === 'copy') {
      handleShare(platform);
      setShowCopyConfirmation(true);
      setTimeout(() => {
        setShowCopyConfirmation(false);
      }, 2000);
    } else {
      handleShare(platform);
    }
  };

  return (
    <AnimatePresence>
      {showShareMenu && (
        <>
          {/* Share Menu Backdrop - transparent to clicks except for the menu itself */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={TRANSITION}
            className="fixed inset-0 bg-black/60 z-[100]"
            onClick={handleBackdropClick}
          />
          
          {/* Share Menu - Guaranteed center positioning */}
          <div className="fixed inset-x-0 top-14 z-[110] flex justify-center items-center">
            <motion.div
              initial={{ opacity: 0, y: -5 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -5 }}
              transition={TRANSITION}
              className="bg-black/90 border border-white/10 rounded-lg py-3 px-6 shadow-lg shadow-black/30 w-auto"
              onClick={(e) => e.stopPropagation()} // Prevent clicks from closing the menu
            >
              <div className="flex items-center justify-center space-x-8">
                <button
                  onClick={(e) => handleShareClick(e, 'whatsapp')}
                  aria-label="Share on WhatsApp"
                  type="button"
                  className="p-2 text-white hover:text-white/90 active:text-white/70 touch-manipulation"
                >
                  <MessageCircle size={22} />
                </button>
                
                <button
                  onClick={(e) => handleShareClick(e, 'twitter')}
                  aria-label="Share on Twitter"
                  type="button"
                  className="p-2 text-white hover:text-white/90 active:text-white/70 touch-manipulation"
                >
                  <Twitter size={22} />
                </button>
                
                <button
                  onClick={(e) => handleShareClick(e, 'facebook')}
                  aria-label="Share on Facebook"
                  type="button"
                  className="p-2 text-white hover:text-white/90 active:text-white/70 touch-manipulation"
                >
                  <Facebook size={22} />
                </button>

                <button
                  onClick={(e) => handleShareClick(e, 'linkedin')}
                  aria-label="Share on LinkedIn"
                  type="button"
                  className="p-2 text-white hover:text-white/90 active:text-white/70 touch-manipulation"
                >
                  <Linkedin size={22} />
                </button>
                
                <div className="relative">
                  <button
                    onClick={(e) => handleShareClick(e, 'copy')}
                    aria-label="Copy link"
                    type="button"
                    className="p-2 text-white hover:text-white/90 active:text-white/70 touch-manipulation"
                  >
                    <Copy size={22} />
                  </button>
                  {showCopyConfirmation && (
                    <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-black/90 text-white text-xs px-3 py-1 rounded whitespace-nowrap border border-white/10">
                      Link copied
                    </div>
                  )}
                </div>
              </div>
            </motion.div>
          </div>
        </>
      )}
    </AnimatePresence>
  );
} 