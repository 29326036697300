export const content = {
  title: 'Podcasts and Audiobooks',
  subtitle: 'Vocal and Soundtrack Editing, Technical Audio Consulting, Advanced Recording Restoration',
  paragraphs: [
    `Professional podcast and audiobook production services delivering broadcast-quality sound that captures and retains listeners. Specialized techniques in voice clarity, noise reduction, and acoustic balancing ensure your content stands out with crystal-clear audio that elevates your storytelling and engages your audience.`,
    `From noise reduction and EQ balancing to ensuring consistent levels throughout episodes, I provide comprehensive audio post-production services. My goal is to make your content stand out in the competitive world of digital audio.`,
    `Whether you're launching a new podcast series or producing an audiobook, I'll work with you to achieve pristine audio quality that keeps listeners engaged. Let's create content that not only informs but also delivers an exceptional listening experience.`
  ],
  image: {
    src: '/images/optimized/giovanni-cordova-remote-professional-podcast-audiobook-sound-restoration-2025.webp',
    alt: 'Studio equipment',
    credit: {
      photographer: 'Silvia Monge',
      location: 'Amsterdam',
      year: '2021'
    }
  }
}; 