import { motion, AnimatePresence } from 'framer-motion';

interface BlurBackdropProps {
  isVisible: boolean;
}

export const BlurBackdrop = ({ isVisible }: BlurBackdropProps) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.15, ease: "easeInOut" }}
          className="fixed inset-0 backdrop-blur-md pointer-events-none z-[50] bg-[#0A0A0A]/40"
          style={{
            WebkitBackdropFilter: 'blur(8px)',
            backdropFilter: 'blur(8px)',
          }}
        />
      )}
    </AnimatePresence>
  );
}; 