'use client';

import React, { useState, useEffect } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import MobileMenu from './mobile-header/MobileMenu';
import ShareMenu from './mobile-header/ShareMenu';
import Header from './mobile-header/Header';
import { shareOnPlatform } from './mobile-header/shareUtils';

// Define shared transition settings for consistency across components
const TRANSITION = {
  duration: 0.2,
  ease: [0.16, 1, 0.3, 1]
};

// Page transition delay (in ms)
const PAGE_TRANSITION_DELAY = 50;

export default function MobileHeader() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);
  const [showShareMenu, setShowShareMenu] = useState(false);
  const [isNavigating, setIsNavigating] = useState(false);
  const pathname = usePathname();
  const router = useRouter();
  
  // Determine page type for conditional rendering
  const isGalleryPage = pathname === '/gallery/mobile' || pathname === '/gallery';
  const isExplorePage = (pathname.includes('/explore/') && 
                       !pathname.includes('/explore/mixing-and-mastering/mobile') && 
                       !pathname.includes('/explore/podcasts-and-audiobooks/mobile')) ||
                       pathname.includes('/before-after') ||
                       pathname.includes('/contact');
  const isHomePage = pathname === '/' || pathname === '/mobile';
  
  // Close share menu when mobile menu opens
  useEffect(() => {
    if (isMobileMenuOpen) {
      setShowShareMenu(false);
    }
  }, [isMobileMenuOpen]);
  
  // Prevent body scroll when menu is open
  useEffect(() => {
    if (isMobileMenuOpen || showShareMenu) {
      document.body.style.overflow = 'hidden';
      // Remove any gradient effect variables
      document.documentElement.style.setProperty('--nav-hover', '0');
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isMobileMenuOpen, showShareMenu]);
  
  // Handle smooth page navigation
  const handleNavigation = (href: string, e?: React.MouseEvent) => {
    if (e) e.preventDefault();
    
    // If already on the page being navigated to, just close the menu
    if (pathname === href || (href === '/' && isHomePage)) {
      setIsMobileMenuOpen(false);
      setIsNavigating(false);
      return;
    }
    
    setIsNavigating(true);
    setIsMobileMenuOpen(false);
    
    // Short delay to allow menu close animation to play
    setTimeout(() => {
      router.push(href);
      // Reset navigation state after navigation is complete
      setIsNavigating(false);
    }, PAGE_TRANSITION_DELAY);
  };
  
  // Handle sharing content
  const handleShare = async (platform: string) => {
    await shareOnPlatform(platform);
    setShowShareMenu(false);
  };
  
  return (
    <div className="bg-[#0A0A0A] mobile-header-container">
      {/* Header */}
      <Header
        isHeaderVisible={true}
        isGalleryPage={isGalleryPage}
        isHomePage={isHomePage}
        setIsMobileMenuOpen={setIsMobileMenuOpen}
        setShowShareMenu={setShowShareMenu}
        handleNavigation={handleNavigation}
      />
      
      {/* Mobile Menu */}
      <MobileMenu
        isMobileMenuOpen={isMobileMenuOpen}
        activeDropdown={activeDropdown}
        setIsMobileMenuOpen={setIsMobileMenuOpen}
        setActiveDropdown={setActiveDropdown}
        handleNavigation={handleNavigation}
      />
      
      {/* Share Menu */}
      <ShareMenu
        showShareMenu={showShareMenu}
        setShowShareMenu={setShowShareMenu}
        handleShare={handleShare}
      />
    </div>
  );
} 