'use client';

import React, { useState } from 'react';
import Image, { ImageProps } from 'next/image';
import { cn } from '@/lib/utils';

export interface ImageWithFallbackProps extends Omit<ImageProps, 'placeholder'> {
  fallbackColor?: string;
  blurDataURL?: string;
  placeholder?: 'blur' | 'empty';
  priority?: boolean;
  loading?: 'eager' | 'lazy';
  fetchPriority?: 'high' | 'auto';
}

// List of allowed Next.js Image props
const VALID_IMAGE_PROPS = [
  'src', 'alt', 'width', 'height', 'fill', 'sizes', 'quality',
  'priority', 'loading', 'placeholder', 'blurDataURL', 'unoptimized',
  'layout', 'objectFit', 'objectPosition', 'lazyBoundary', 'lazyRoot',
  'onLoadingComplete', 'fetchPriority', 'decoding', 'crossOrigin',
  'referrerPolicy', 'onLoad', 'onError'
];

export const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({
  src,
  alt,
  fallbackColor = '#1a1a1a',
  blurDataURL,
  placeholder = 'blur',
  priority = false,
  loading,
  fetchPriority,
  className,
  ...props
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);

  // Determine if this image is likely the LCP image
  const isLikelyLCP = priority === true || fetchPriority === 'high';

  // Set appropriate loading and fetchPriority for best performance
  const effectiveLoading = isLikelyLCP ? 'eager' : (loading || (priority ? 'eager' : 'lazy'));
  const effectiveFetchPriority = isLikelyLCP ? 'high' : (fetchPriority || (priority ? 'high' : 'auto'));

  // If no blurDataURL is provided but placeholder is blur, create a minimal one
  const effectiveBlurDataURL = 
    placeholder === 'blur' && !blurDataURL 
      ? 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8/5+hHgAHggJ/PchI7wAAAABJRU5ErkJggg=='
      : blurDataURL;

  const handleLoad = () => {
    setIsLoaded(true);
  };

  const handleError = () => {
    setHasError(true);
  };

  if (hasError) {
    return (
      <div className="w-full h-full flex items-center justify-center p-4 text-center" style={{ backgroundColor: fallbackColor }}>
        {alt || 'Image could not be loaded'}
      </div>
    );
  }
  
  // Filter out only valid Next.js Image props
  const safeProps = Object.fromEntries(
    Object.entries(props).filter(([key]) => VALID_IMAGE_PROPS.includes(key))
  );

  return (
    <div className="relative w-full h-full" style={{ backgroundColor: fallbackColor }}>
      <Image
        src={src}
        alt={alt}
        loading={effectiveLoading}
        fetchPriority={effectiveFetchPriority}
        onLoad={handleLoad}
        onError={handleError}
        placeholder={placeholder}
        blurDataURL={effectiveBlurDataURL}
        quality={safeProps.quality || 80}
        className={cn(
          "transition-opacity duration-300 ease-in-out opacity-0 image-fill",
          isLoaded ? "opacity-100" : "opacity-0",
          className
        )}
        {...safeProps}
      />
    </div>
  );
}; 