const months = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

export const formatDate = (dateString: string): string => {
  // If the date is already in "Month Year" format, return it as is
  if (months.some(month => dateString.startsWith(month))) {
    return dateString;
  }

  // Otherwise, try to parse it as a full date
  const date = new Date(dateString);
  
  // Check if date is valid
  if (isNaN(date.getTime())) {
    return '';
  }
  
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  
  return `${month} ${year}`;
}; 