'use client';

import React, { useMemo } from 'react';
import { Review } from '@/data/reviews';
import { formatDate } from '@/utils/dateUtils';
import { EnhancedReview } from '@/components/SEO/EnhancedReview';

interface ReviewCardProps {
  review: Review;
}

// Precomputed star rating elements to avoid recreating them for each card
const StarRatings = {
  1: createStarRating(1),
  2: createStarRating(2),
  3: createStarRating(3),
  4: createStarRating(4),
  5: createStarRating(5)
};

function createStarRating(count: number) {
  return Array(count).fill('★').map((star, index) => (
    <span 
      key={index} 
      className="bg-gradient-to-br from-[#FFD700] via-[#FDB931] to-[#FF8C00] text-transparent bg-clip-text text-[24px]"
      style={{
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        fontFamily: 'Courier New, monospace',
        lineHeight: '1',
        display: 'inline-block',
        transform: 'translateY(-2px)',
        marginLeft: '4px'
      }}
    >
      {star}
    </span>
  ));
}

// Memoized component to prevent unnecessary re-renders
const ReviewCard = React.memo(({ review }: ReviewCardProps) => {
  // Pre-compute country flag URL to avoid layout shifts
  const flagUrl = useMemo(() => {
    return review.country ? `/images/flags/${review.country}.jpg` : '';
  }, [review.country]);

  // Pre-format date to avoid computations during render
  const formattedDate = useMemo(() => {
    return review.date ? formatDate(review.date) : '';
  }, [review.date]);
  
  const content = (
    <div className="relative group">
      <div 
        className="relative p-3 rounded-xl border border-white/50 overflow-hidden transition-all duration-300 group-hover:border-white/75"
        style={{
          transform: 'translateZ(0)',
          backfaceVisibility: 'hidden',
          willChange: 'transform, opacity',
          contain: 'content'
        }}
      >
        <div className="relative flex flex-col space-y-2">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <h3 className="font-medium text-[15px] tracking-wide text-white/75 transition-colors duration-300 group-hover:text-white">
                {review.author}
              </h3>
              {flagUrl && (
                <div className="w-4 h-4 rounded-full overflow-hidden relative">
                  <img
                    src={flagUrl}
                    alt={`${review.country} flag`}
                    width={16}
                    height={16}
                    loading="lazy"
                    fetchPriority="low"
                    decoding="async"
                    className="absolute inset-0 w-full h-full object-cover"
                    style={{
                      contentVisibility: 'auto'
                    }}
                  />
                </div>
              )}
            </div>
            <div className="flex items-center text-[#FFD700] tracking-[-0.05em] ml-2">
              {StarRatings[review.rating as keyof typeof StarRatings]}
            </div>
          </div>
          <p className="text-[13px] leading-[1.4] font-light text-white/75 transition-colors duration-300 group-hover:text-white">
            {review.text}
          </p>
          {formattedDate && (
            <span className="text-[11px] text-white/40 transition-colors duration-300 group-hover:text-white/50">
              {formattedDate}
            </span>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <EnhancedReview review={review}>
      {content}
    </EnhancedReview>
  );
}, (prevProps, nextProps) => {
  // Custom comparison function for memoization
  // Only re-render if the review ID changes
  return prevProps.review.id === nextProps.review.id;
});

ReviewCard.displayName = 'ReviewCard';

export default ReviewCard; 